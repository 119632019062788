import { Svg, Path } from "react-native-svg";

const BadgeGeneral = (props) => (
  <Svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M7.00015 5.038L5.30848 6.01548V7.98455L7.00015 8.96203L8.69181 7.98455V6.01548L7.00015 5.038Z"
      fill={props.fill}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.07968 1.60932C8.1357 1.47282 8.16659 1.32335 8.16659 1.16667C8.16659 0.522334 7.64425 0 6.99992 0C6.35559 0 5.83325 0.522334 5.83325 1.16667C5.83325 1.32335 5.86414 1.47282 5.92016 1.60932L2.72029 3.43544C2.51103 3.12263 2.15453 2.91667 1.74992 2.91667C1.10559 2.91667 0.583252 3.439 0.583252 4.08333C0.583252 4.72767 1.10559 5.25 1.74992 5.25V8.75C1.10559 8.75 0.583252 9.27233 0.583252 9.91667C0.583252 10.561 1.10559 11.0833 1.74992 11.0833C2.15453 11.0833 2.51103 10.8774 2.72029 10.5646L5.92016 12.3907C5.86414 12.5272 5.83325 12.6766 5.83325 12.8333C5.83325 13.4777 6.35559 14 6.99992 14C7.64425 14 8.16659 13.4777 8.16659 12.8333C8.16659 12.6766 8.1357 12.5272 8.07968 12.3907L11.2795 10.5646C11.4888 10.8774 11.8453 11.0833 12.2499 11.0833C12.8943 11.0833 13.4166 10.561 13.4166 9.91667C13.4166 9.27233 12.8943 8.75 12.2499 8.75V5.25C12.8943 5.25 13.4166 4.72767 13.4166 4.08333C13.4166 3.439 12.8943 2.91667 12.2499 2.91667C11.8453 2.91667 11.4888 3.12263 11.2795 3.43544L8.07968 1.60932ZM6.42684 3.8871C6.78163 3.6821 7.21866 3.6821 7.57346 3.8871L9.40131 4.94327C9.75698 5.14879 9.97515 5.52846 9.97515 5.9383V8.06173C9.97515 8.47157 9.75698 8.85124 9.40131 9.05676L7.57346 10.1129C7.21866 10.3179 6.78163 10.3179 6.42684 10.1129L4.59898 9.05676C4.24331 8.85124 4.02515 8.47157 4.02515 8.06173V5.9383C4.02515 5.52846 4.24331 5.14879 4.59898 4.94327L6.42684 3.8871Z"
      fill={props.fill}
    />
  </Svg>
);

export default BadgeGeneral;
