import React from "react";
import { View, Pressable, StyleSheet } from "react-native";
import { MotiView } from "moti";
import CustomText from "../../../common/general/CustomText/CustomText";
import Divider from "../../../common/layout/Divider/Divider";

function DataItem({
  title,
  avgTime,
  successPoints,
  confusionPoints,
  questionsAsked,
  activeInteractionCount,
  distractions,
  enrollment,
  sentiment,
}) {
  return (
    <MotiView
      aria-label="Task card"
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ type: "timing", duration: 500 }}>
      <Pressable onPress={null} accessibilityHint="Press to see details">
        <View style={styles.container}>
          {/* Title Row */}
          <View style={styles.titleRow}>
            <CustomText useTranslationText={false} text={title} size="s" weight="medium" style={styles.title} />
          </View>

          {/* Data Points Row */}
          <View style={styles.dataRow}>
            {/* 1) AVG. TIME */}
            <View style={styles.dataItem}>
              <CustomText
                useTranslationText={false}
                size="s"
                weight="bold"
                text={`${enrollment}`}
                style={styles.boldText}
              />
              <CustomText
                useTranslationText={false}
                size="s"
                weight="medium"
                text="Enrollments"
                style={styles.subText}
              />
            </View>

            {/* 2) Success Points */}
            <View style={styles.dataItem}>
              <CustomText
                useTranslationText={false}
                size="s"
                weight="bold"
                text={`${successPoints}`}
                style={styles.boldText}
              />
              <CustomText useTranslationText={false} size="s" weight="medium" text="SUCCESS" style={styles.subText} />
            </View>

            {/* 3) Confusion Points */}
            <View style={styles.dataItem}>
              <CustomText
                useTranslationText={false}
                size="s"
                weight="bold"
                text={`${confusionPoints}`}
                style={styles.boldText}
              />
              <CustomText useTranslationText={false} size="s" weight="medium" text="CONFUSION" style={styles.subText} />
            </View>

            {/* 4) Questions Asked */}
            <View style={styles.dataItem}>
              <CustomText
                useTranslationText={false}
                size="s"
                weight="bold"
                text={`${questionsAsked}`}
                style={styles.boldText}
              />
              <CustomText useTranslationText={false} size="s" weight="medium" text="QUESTIONS" style={styles.subText} />
            </View>

            {/* 5) Active Interaction */}
            <View style={styles.dataItem}>
              <CustomText
                useTranslationText={false}
                size="s"
                weight="bold"
                text={`${activeInteractionCount}`}
                style={styles.boldText}
              />
              <CustomText
                useTranslationText={false}
                size="s"
                weight="medium"
                text="Interactions"
                style={styles.subText}
              />
            </View>

            {/* 6) Distractions */}
            <View style={styles.dataItem}>
              <CustomText
                useTranslationText={false}
                size="s"
                weight="bold"
                text={`${distractions}`}
                style={styles.boldText}
              />
              <CustomText
                useTranslationText={false}
                size="s"
                weight="medium"
                text="DISTRACTIONS"
                style={styles.subText}
              />
            </View>
          </View>
        </View>
      </Pressable>
    </MotiView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#e0e0e0",
  },
  titleRow: {
    marginBottom: 12,
    flexDirection: "row", // Ensure the title row is aligned properly
  },
  title: {
    flex: 1,
    color: "#344054",
    fontSize: 16,
    fontWeight: "500",
  },
  dataRow: {
    flexDirection: "row", // All data items in a row
    flexWrap: "wrap", // Allow wrapping if necessary
    justifyContent: "space-between", // Space out data points evenly
  },
  dataItem: {
    alignItems: "center",
    marginBottom: 16,
    width: "30%", // Ensures the items are evenly distributed
  },
  boldText: {
    color: "#000",
  },
  subText: {
    fontSize: 10,
    lineHeight: 18,
    color: "#667085",
    textTransform: "uppercase",
  },
});

export default DataItem;
