import React from "react";
import { View, Text, Pressable, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import TimeLeftSmall from "../../svgs/common/TimeLeftSmall";
import CheckedBadge from "../../svgs/common/CheckedBadge";
import CustomProgressBar from "../../common/general/CustomProgressBar/CustomProgressBar";
import NonComplete from "../../svgs/common/NonComplete";

function AiTutorCardProgress({
  title,
  isHovered,
  isComplete = false,
  onPress,
  onHoverIn,
  onHoverOut,
  totalObjectives,
  completedObjectives,
  resourceDurationMinutes,
}) {
  const progressPercentage = totalObjectives > 0 ? (completedObjectives / totalObjectives) * 100 : 0;

  return (
    <>
      <Pressable
        onPress={onPress}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
        style={({ pressed }) => [
          styles.lessonItem,
          isHovered && styles.lessonItemHovered,
          pressed && styles.lessonItemPressed,
        ]}>
        <View style={styles.lessonItemLeft}>
          <CustomText text={title} weight="bold" style={styles.lessonItemTitle} useTranslationText={false} />
          <View style={styles.progressContainer}>
            <View style={styles.progressStatus}>
              {!isComplete && (
                <View style={styles.timeContainer}>
                  <TimeLeftSmall size={16} color="#667085" />
                  <Text style={styles.timeLeftText}>{`${resourceDurationMinutes} min`}</Text>
                </View>
              )}

              <View style={styles.progressBar}>
                <CustomProgressBar
                  ariaLabel="Course progress bar"
                  progress={progressPercentage}
                  color={isComplete ? "#22C55E" : "#3E68FE"}
                />
              </View>
            </View>
          </View>
        </View>
        {isComplete ? <CheckedBadge /> : <NonComplete />}
      </Pressable>
    </>
  );
}

const styles = StyleSheet.create({
  timeLeftText: {
    color: "#667085",
    fontSize: 12,
    fontWeight: "500",
  },
  progressBar: {
    width: 150,
    maxWidth: 150,
    marginLeft: 8,
    marginRight: 8,
  },
  timeContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
  },
  lessonItem: {
    // margin: 10,
    marginVertical: 8,
    marginHorizontal: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 24,
    height: 75,
    borderWidth: 1,
    borderColor: "#EAECF0",
    borderRadius: 16,
    outlineWidth: 0, // Prevent default outline
    outlineColor: "transparent",
    outlineStyle: "solid",
    transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1)", // Smooth transition for hover effect
  },
  lessonItemHovered: {
    outlineWidth: 2,
    outlineColor: "#D0D5DD",
    outlineStyle: "solid",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional hover shadow
  },
  lessonItemPressed: {
    backgroundColor: "#F5F5F5", // Slight background change when pressed
  },
  lessonItemLeft: {
    flex: 1,
    marginRight: 16,
  },
  lessonItemTitle: {
    marginBottom: 4,
  },
  progressContainer: {
    gap: 8,
  },
  progressStatus: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

export default AiTutorCardProgress;
