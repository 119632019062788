import { useEffect, useState } from "react";
import { MotiView } from "moti";
import { useNavigation } from "@react-navigation/native";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Sentry from "../../../utils/sentry";
import { useGetCourseQuery } from "../../../graphql/generated/graphql";
import Skeleton from "react-loading-skeleton";
import AiTutorCardProgressList from "../../../components/lms/AiTutorCardProgressList/AiTutorCardProgressList";

const AiTutorCardProgressListContainer = ({ integrationId, courseId, onProgressUpdate }) => {
  const [modulesWithAiTutors, setModulesWithAiTutors] = useState([]);
  const navigation = useNavigation();

  const { data: course, loading } = useGetCourseQuery({
    variables: {
      id: courseId,
    },
  });

  useEffect(() => {
    if (course && !loading) {
      const formattedModulesWithAiTutors = course?.getCourse.data[0].modules.map((module) => {
        const aiTutors = module.resources[0].aitutors.map((aiTutor) => {
          // Extract total objectives from aiTutor
          const aiTutorTotalObjectives =
            aiTutor.objectives && aiTutor.objectives.length > 0 ? aiTutor.objectives.length : 0;

          // Calculate completed objectives from aiTutor
          const aiTutorCompletedObjectives =
            aiTutor.objectives && aiTutor.objectives.length > 0
              ? aiTutor.objectives.filter(
                  (objective) => objective.userHasProgressConnection?.edges?.[0]?.properties?.status === "completed"
                ).length
              : 0;

          const isComplete = aiTutorCompletedObjectives > 0 && aiTutorCompletedObjectives === aiTutorTotalObjectives;
          // Calculate resource duration
          const resourceDurationMinutes = (aiTutorTotalObjectives - aiTutorCompletedObjectives) * 10;

          return {
            ...aiTutor,
            details: {
              isComplete,
              totalObjectives: aiTutorTotalObjectives,
              completedObjectives: aiTutorCompletedObjectives,
              resourceDurationMinutes,
            },
          };
        });

        return {
          ...module,
          resources: [{ aitutors: aiTutors }],
        };
      });

      const totalObjectivesCount = formattedModulesWithAiTutors.reduce((acc, module) => {
        return acc + module.resources[0].aitutors.reduce((acc, aiTutor) => acc + aiTutor.details.totalObjectives, 0);
      }, 0);

      const completedObjectivesCount = formattedModulesWithAiTutors.reduce((acc, module) => {
        return (
          acc + module.resources[0].aitutors.reduce((acc, aiTutor) => acc + aiTutor.details.completedObjectives, 0)
        );
      }, 0);

      // Calculate total progress and total duration
      const totalProgress = totalObjectivesCount > 0 ? (completedObjectivesCount / totalObjectivesCount) * 100 : 0;
      const totalDurationMinutes = (totalObjectivesCount - completedObjectivesCount) * 10;

      setModulesWithAiTutors(formattedModulesWithAiTutors);
      onProgressUpdate(totalProgress, totalDurationMinutes);
    }
  }, [course]);

  const handleResourcePress = (id) => {
    const aiTutor = modulesWithAiTutors
      .flatMap((module) => module.resources[0].aitutors)
      .find((aiTutor) => aiTutor.id === id);

    if (aiTutor && aiTutor.details) {
      const params = {
        resource_type: "AITutor",
        resource_id: aiTutor.id,
        course_id: courseId,
        integration_id: integrationId,
      };

      navigation.navigate("Custom Resource Student", params);
    } else {
      Sentry.captureException(`Resource with id ${id} not found or missing details.`);
    }
  };

  const handleOnHoverIn = (id) => {
    setModulesWithAiTutors((prev) =>
      prev.map((module) => ({
        ...module,
        resources: [
          {
            aitutors: module.resources[0].aitutors.map((aiTutor) =>
              aiTutor.id === id ? { ...aiTutor, isHovered: true } : aiTutor
            ),
          },
        ],
      }))
    );
  };

  const handleOnHoverOut = (id) => {
    setModulesWithAiTutors((prev) =>
      prev.map((module) => ({
        ...module,
        resources: [
          {
            aitutors: module.resources[0].aitutors.map((aiTutor) =>
              aiTutor.id === id ? { ...aiTutor, isHovered: false } : aiTutor
            ),
          },
        ],
      }))
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <ScrollView style={{ flex: 1 }}>
          {[1, 2, 3].map((_, idx) => (
            <View key={idx}>
              <CustomSpacing type="vertical" size="l" />
              <Skeleton height={18} width={60} />
              <CustomSpacing type="vertical" size="s" />
              <Skeleton height={32} width={200} />
              <CustomSpacing type="vertical" size="l" />
              {[1, 2, 3].map((__, idx2) => (
                <Skeleton height={80} style={{ marginBottom: 10 }} key={idx2} />
              ))}
            </View>
          ))}
        </ScrollView>
      );
    }

    return (
      <ScrollView style={{ flex: 1 }}>
        {modulesWithAiTutors.map((module) => (
          <View key={module.id}>
            <CustomSpacing type="vertical" size="l" />
            <CustomText
              text={"MODULE"}
              size="m"
              style={{ fontFamily: "Inter", fontSize: 12, lineHeight: 18, letterSpacing: "0.06", color: "#667085" }}
              useTranslationText={false}
            />
            <CustomText
              text={module.title}
              size="l"
              style={{ fontFamily: "AbrilFatface", fontSize: 24, lineHeight: 32 }}
              useTranslationText={false}
            />
            <CustomSpacing type="vertical" size="l" />
            <AiTutorCardProgressList
              items={module.resources[0].aitutors}
              onPress={handleResourcePress}
              onHoverIn={handleOnHoverIn}
              onHoverOut={handleOnHoverOut}
            />
          </View>
        ))}
      </ScrollView>
    );
  };

  return (
    <MotiView
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      delay={1500}
      transition={{ type: "timing", duration: 600 }}
      style={{ flex: 1 }}
      aria-live="polite">
      {renderContent()}
    </MotiView>
  );
};

export default AiTutorCardProgressListContainer;
