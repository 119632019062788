// A wrapper around the CustomButton component for standardizing the button in the new Revamp

import React from "react";
import CustomButton from "./CustomButton";
import { StyleSheet } from "react-native";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";
import { shadowStyles } from "../../../../styles/shadow";

export const NewCustomActionButton = ({ text, onPress, leftIcon }) => {
  const { isXLarge } = useResponsiveScreen();
  return (
    <CustomButton
      text={text}
      size="s"
      weight="bold"
      styleType="primaryTransparent"
      style={[
        styles.managementButton,
        shadowStyles.smallShadowButton,
        isXLarge ? {} : styles.fullWidthButton,
        { width: "fit-content" },
      ]}
      onPress={onPress}
      leftIcon={leftIcon}
      rightIcon={null}
      textStyle={{ fontWeight: "bold" }}
      disabled={false}
      textProps={{}}
    />
  );
};

const styles = StyleSheet.create({
  managementButton: {
    color: "#3E68FE",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    borderRadius: 8,
    backgroundColor: "#fff",
  },
  fullWidthButton: {
    width: "100%",
  },
});
