import * as React from "react";
import Svg, { Path } from "react-native-svg";

function CharLine({ fill = "#3E68FE", ...props }) {
  return (
    <Svg width={17} height={17} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M14.75 13a.75.75 0 01-.75.75H2a.75.75 0 01-.75-.75V3a.75.75 0 011.5 0v5.347l2.756-2.41a.75.75 0 01.944-.035l3.514 2.635 3.542-3.1a.75.75 0 11.988 1.125l-4 3.5a.75.75 0 01-.944.036L6.036 7.465 2.75 10.341v1.909H14a.75.75 0 01.75.75z"
        fill={fill}
      />
    </Svg>
  );
}

export default CharLine;
