import React from "react";
import { StyleSheet, View } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { EmailIcon, PhoneIcon } from "../../svgIcons";
import { OTPPreference } from "../../../containers/settings/ProfileTabContainer/TwoFactorAuthModalContainer/TwoFactorAuthModalContainer";

const TwoFactorSetup = ({ selectedMethod, setSelectedMethod }) => {
  return (
    <View style={styles.container}>
      <CustomText
        text="Choose verification method"
        size="m"
        textType="text"
        weight="medium"
        style={styles.headerText}
      />
      <CustomSpacing type="vertical" size="l" />
      <View style={styles.methodContainer}>
        <CustomButton
          text="Email"
          styleType={selectedMethod === OTPPreference.EMAIL ? "primary" : "secondary"}
          onPress={() => setSelectedMethod(OTPPreference.EMAIL)}
          style={styles.methodButton}
          leftIcon={<EmailIcon fill={selectedMethod === OTPPreference.EMAIL ? "#fff" : "#3E68FE"} />}
          rightIcon={null}
          textStyle={{}}
          disabled={false}
          textProps={{}}
        />
        <CustomButton
          text="Text Message"
          styleType={selectedMethod === OTPPreference.SMS ? "primary" : "secondary"}
          onPress={() => setSelectedMethod(OTPPreference.SMS)}
          style={styles.methodButton}
          leftIcon={<PhoneIcon fill={selectedMethod === OTPPreference.SMS ? "#fff" : "#3E68FE"} />}
          rightIcon={null}
          textStyle={{}}
          disabled={false}
          textProps={{}}
        />
      </View>
      <CustomSpacing type="vertical" size="xxl" />
      <CustomButton
        text="Continue"
        styleType="primary"
        //   onPress={handleContinue}
        disabled={!selectedMethod}
        style={styles.continueButton}
        leftIcon={null}
        rightIcon={null}
        textStyle={{}}
        textProps={{}}
        onPress={() => {}}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  headerText: {
    textAlign: "center",
  },
  methodContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  methodButton: {
    flex: 1,
    marginHorizontal: 8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 8,
  },
  continueButton: {
    width: "100%",
  },
});

export default TwoFactorSetup;
