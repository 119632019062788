import React from "react";
import CustomModal from "../../../../components/common/general/CustomModal/CustomModal";
import DeleteAccount from "../../../../components/settings/DeleteAccount/DeleteAccount";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";

const DeleteAccountModalContainer = ({ visible, onClose, onDelete }) => {
  const { isMedium } = useResponsiveScreen();

  const handleDelete = (text) => {
    onDelete(text);
  };

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title="Delete Account"
      style={{ width: isMedium ? "50%" : "90%" }}
      exitFill={undefined}
      onShow={undefined}
      ariaLabel={undefined}>
      <DeleteAccount cancelButtonClick={onClose} deleteButtonClick={handleDelete} />
    </CustomModal>
  );
};

export default DeleteAccountModalContainer;
