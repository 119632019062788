import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { PlusCircle } from "../../svgIcons";
import CustomText from "../../common/general/CustomText/CustomText";
import { ListItem } from "@rneui/themed";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import { DownArrow } from "../../svgs/common";
import CustomCourseworkMenuItem from "./CustomCourseworkMenuItem";
import { shadowStyles } from "../../../styles/shadow";

function CustomCourseWorkContentMenu({
  data,
  onSelectModule,
  onSelectActivity,
  loadingData,
  selectedModule,
  selectedActivity,
  onAddModule,
}) {
  const { isXLarge, isMedium, hp } = useResponsiveScreen();
  const [expandedModule, setExpandedModule] = useState(null);
  const [sortedModules, setSortedModules] = useState([]);

  useEffect(() => {
    if (data?.modules) {
      // Sort modules by order, putting order=0 at the bottom
      const sorted = [...data.modules].sort((a, b) => {
        if (a.order === 0) return 1;
        if (b.order === 0) return -1;
        return (a.order ?? 0) - (b.order ?? 0);
      });
      setSortedModules(sorted);

      if (!expandedModule && sorted.length > 0) {
        const firstModule = sorted[0];
        setExpandedModule(firstModule.title);
        onSelectModule(firstModule);
      }
    }
  }, [data?.modules]);

  const handleSelectModule = (module) => {
    const isExpanded = expandedModule === module.title;

    if (selectedActivity && selectedModule?.id === module.id) {
      setExpandedModule(module.title);
      onSelectModule(module);
      return;
    }

    setExpandedModule(isExpanded ? null : module.title);
    if (!isExpanded) {
      onSelectModule(module);
    }
  };

  const handleItemPress = (item) => {
    onSelectActivity(item);
  };

  return (
    <View style={[styles.container, { maxWidth: isXLarge ? 400 : isMedium ? 240 : hp(100) }]} role="menu">
      <CustomSpacing type="vertical" size="xxl" />
      <View style={{ paddingHorizontal: 20 }}>
        <View style={styles.menuContainer}>
          <CustomText role="header" aria-level="3" text="Modules" weight="ultraBold" style={{ color: "#121212" }} />
          <CustomButton
            text="Add Module"
            leftIcon={<PlusCircle />}
            styleType="primaryTransparent"
            size="s"
            style={[styles.addButton, shadowStyles.smallShadowButton]}
            textStyle={styles.addText}
            onPress={onAddModule}
            aria-label="Add Module"
          />
        </View>
      </View>

      <ScrollView role="list" showsVerticalScrollIndicator={false} style={styles.scrollView}>
        {sortedModules.length > 0 ? (
          sortedModules.map((module) => {
            const items = [
              ...(module.resources?.[0]?.aitutors || []).map((item) => ({
                ...item,
                type: "Interactive Learning Experience",
              })),
              ...(module.resources?.[0]?.pages || []),
            ];

            const isModuleSelected =
              selectedModule?.id === module.id || items.some((item) => item.id === selectedActivity?.id);

            return (
              <ListItem.Accordion
                key={module.id}
                role="button"
                aria-label={`${module.title} (Order: ${module.order ?? "Unordered"})`}
                isExpanded={expandedModule === module.title}
                onPress={() => handleSelectModule(module)}
                content={
                  <CustomText
                    useTranslationText={false}
                    text={module.title}
                    weight={isModuleSelected ? "bold" : "medium"}
                    style={[styles.moduleText, isModuleSelected && styles.selectedModuleText]}
                  />
                }
                icon={<DownArrow fill="#344054" />}
                containerStyle={[styles.accordionContainer, isModuleSelected && styles.selectedModuleContainer]}>
                {items.map((item) => {
                  const isActivitySelected = selectedActivity?.id === item.id;
                  return (
                    <CustomCourseworkMenuItem
                      key={item.id}
                      onItemPress={() => handleItemPress(item)}
                      name={item.title}
                      type={item.type}
                      isSelected={isActivitySelected}
                      textStyle={[styles.activityText, isActivitySelected && styles.selectedActivityText]}
                    />
                  );
                })}
              </ListItem.Accordion>
            );
          })
        ) : (
          <View style={styles.noModulesContainer}>
            <CustomText
              text="To get started, click the Add Module button above"
              size="m"
              style={styles.noModulesText}
              useTranslationText={false}
            />
          </View>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: "#EAECF0",
    backgroundColor: "#fff",
  },
  scrollView: {
    paddingHorizontal: 20,
  },
  menuContainer: {
    paddingVertical: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    borderBottomColor: "#D0D5DD",
    borderBottomWidth: 1,
  },
  accordionContainer: {
    paddingHorizontal: 0,
    paddingVertical: 12,
  },
  addButton: {
    border: "1px solid rgba(5, 6, 15, 0.10)",
  },
  addText: {
    paddingLeft: 10,
  },
  moduleText: {
    color: "#344054",
    fontWeight: "500",
    flex: 1,
  },
  // orderText: {
  //   marginLeft: 8,
  //   color: "#667085",
  //   fontSize: 12,
  // },
  selectedModuleText: {
    fontWeight: "700",
    color: "#344054",
  },
  selectedModuleContainer: {
    backgroundColor: "#F2F5FF",
  },
  activityText: {
    fontWeight: "400",
    color: "#344054",
  },
  selectedActivityText: {
    fontWeight: "700",
    color: "#344054",
  },
  noModulesContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F2F5FF",
    marginTop: 20,
    borderRadius: 10,
    padding: 40,
  },
  noModulesText: {
    color: "#344054",
  },
});

export default CustomCourseWorkContentMenu;
