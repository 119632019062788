/* eslint-disable react-native/no-unused-styles */
import React, { useMemo, useState, useRef, useEffect } from "react";
import { Platform, Animated } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { useRoute } from "@react-navigation/native";
import { useAuth } from "../../../hooks/useAuth";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import { useGetAllCoursesBySchoolQuery, useMeQuery, useSchoolsQuery } from "../../../graphql/generated/graphql";
import { useAppConfig } from "../../../AppConfigProvider";
import useWebSocket from "../../../hooks/useWebSocket";
import handleOpenLink from "../../../utils/handleOpenLink";
import { TodosIcon, EducationIcon, CareerIcon } from "../../../components/svgs/menuIcons";
import FeedbackModal from "../../../components/navigation/FeedbackModal/FeedbackModal";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../../../navigation/AppNavigator.web";
import TopMenu from "../../../components/navigation/TopMenu/TopMenu";
import { useChatBar } from "../../../contexts/ChatBarContext";

import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import NotificationsSlideIn from "../../../components/navigation/NotificationSlideIn/NotificationSlideIn";
import SlideInMenuContainer from "../SlideInMenuContainer/SlideInMenuContainer";
import NotificationBar from "../../../components/navigation/NotificationBar/NotificationBar";

type MenuItem = {
  display: string;
  name: keyof RootStackParamList;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  externalURL: string | undefined;
  index: number;
};

function TopMenuContainer() {
  const { isMedium } = useResponsiveScreen();
  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;
  const { sendMessage } = useWebSocket();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const route = useRoute();
  const [notification, setNotification] = useState("");

  const { setShowChat } = useChatBar();

  //Notifcation based on route name.

  useEffect(() => {
    switch (route.name) {
      case "Custom School Student":
        setNotification("Pick a course below to get started");
        break;
      case "Productivity":
        setNotification("Boost your productivity with our tools and resources");
        break;
      case "Portfolio":
        setNotification("Showcase your achievements and skills to employers");
        break;
      case "Analytics":
        setNotification("");
        break;
      case "Academic Plan":
        setNotification(
          "Customize your plan at your own pace. You can choose your general and elective courses now or wait and start with your core classes"
        );
        break;
      default:
        setNotification("");
    }
  }, [route.name]);

  const { logout } = useAuth();
  const { dispatch } = useAppState();

  // const { data: dailyLimitData } = useDailyLimitQuery();
  const { data: meData } = useMeQuery();
  const { data: schoolsData, loading: schoolsLoading } = useSchoolsQuery();

  const { data: courses, loading: coursesLoading } = useGetAllCoursesBySchoolQuery({
    variables: {
      schoolId: schoolsData?.getSchools[0]?.id,
      filterByUser: false,
    },
    skip: !schoolsData || schoolsLoading,
  });

  // Animation setup

  const [progressVisible, setProgressVisible] = useState(false);
  const slideAnim = useRef(new Animated.Value(270)).current;

  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const notificationsSlideAnim = useRef(new Animated.Value(360)).current;

  const [bugReportModalVisible, setBugReportModalVisible] = useState(false);

  const { scope, showFeedback, isLTI, showMiniLogo, key, isAnalyticsEnabled } = appConfig;

  const DomainConfigMenuItems: MenuItem[] = [
    { display: "Courses", name: "Custom Course Student", icon: EducationIcon, externalURL: undefined, index: 1 },
    { display: "Productivity", name: "Productivity", icon: TodosIcon, externalURL: undefined, index: 2 },
    { display: "Portfolio", name: "Portfolio", icon: CareerIcon, externalURL: undefined, index: 3 },
    // Until we can make coaching it's own page
    // @ts-ignore
    { display: "AI Assistant", name: "Coaching", icon: CareerIcon, externalURL: undefined, index: 4 },
    { display: "Analytics", name: "Analytics", icon: CareerIcon, externalURL: undefined, index: 5 },
  ];

  const menuItems = useMemo(
    () => DomainConfigMenuItems.filter((item) => scope.includes(item.name)).sort((a, b) => a.index - b.index),
    [scope]
  );

  useEffect(() => {
    if (notificationsVisible) {
      Animated.timing(notificationsSlideAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(notificationsSlideAnim, {
        toValue: 360,
        duration: 300,
        useNativeDriver: true,
      }).start();
    }
  }, [notificationsVisible, notificationsSlideAnim]);

  useEffect(() => {
    if (progressVisible) {
      Animated.timing(slideAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(slideAnim, {
        toValue: 270,
        duration: 300,
        useNativeDriver: true,
      }).start();
    }
  }, [progressVisible, slideAnim]);

  const handleItemPress = (item) => {
    if (item.name === "Coaching") {
      navigation.navigate("Custom School Student");
      setShowChat(true);
    } else {
      setShowChat(false);
    }
    if (item.externalURL) {
      let newWindow;
      if (Platform.OS === "web") {
        newWindow = window.open("", "_blank");
      }
      handleOpenLink(item.externalURL, newWindow);
    } else if (item.name === "Custom Course Student" || item.name === "Activities") {
      handleCourseworkNavigation();
    } else {
      navigation.navigate(item.name);
      handleNavigate();
    }
  };

  const handleNotificationsToggle = () => {
    setNotificationsVisible(!notificationsVisible);
  };

  const handleCourseworkNavigation = () => {
    if (schoolsData?.getSchools && schoolsData.getSchools.length === 1) {
      navigation.navigate("Custom School Student");
    } else {
      navigation.navigate("Coursework");
    }
    handleNavigate();
  };

  const handleFeedbackPress = async () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });

    setBugReportModalVisible(true);
  };

  const handleCloseBugReportModal = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setBugReportModalVisible(false);
  };

  const handleProgressToggle = () => {
    setProgressVisible(!progressVisible);
  };

  const handleSettingsPress = () => {
    navigation.navigate("Settings");
    handleNavigate();
  };

  const handleLogoutPress = async () => {
    logout();
  };

  const handleNavigate = async () => {
    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: true,
      },
    });

    dispatch({
      type: actions.SET_META,
      payload: {
        isMainMenuExpanded: false,
        isLearnMenuOpen: false,
        isCustomCourseworkMenuOpen: false,
      },
    });
  };

  const showLearnSectionOrName = (name) => {
    if (name === "Tracks" || name === "Categories" || name === "Track Overview") return "Learn";
    if (name === "School") return "Coursework";
    return name;
  };

  return (
    <>
      {progressVisible && !coursesLoading && (
        <SlideInMenuContainer
          courses={courses?.getAllCoursesBySchool.data || []}
          courseIds={courses?.getAllCoursesBySchool.data.map((course) => course.id)}
          isAnalyticsEnabled={isAnalyticsEnabled}
          visible={progressVisible}
          slideAnim={slideAnim}
          onClose={() => setProgressVisible(false)}
          onSettingsPress={handleSettingsPress}
        />
      )}

      <TopMenu
        menuItems={menuItems}
        selectedItem={showLearnSectionOrName(route.name)}
        currentDomain={key}
        showMiniLogo={showMiniLogo}
        userInitial={meData?.me?.name?.charAt(0)}
        isLTI={isLTI}
        showFeedback={showFeedback}
        onItemPress={handleItemPress}
        onFeedbackPress={handleFeedbackPress}
        onSettingsPress={handleProgressToggle}
        onLogoutPress={handleLogoutPress}
        onNotifcationsPress={handleNotificationsToggle}
      />
      <NotificationsSlideIn
        visible={notificationsVisible}
        slideAnim={notificationsSlideAnim}
        onClose={() => setNotificationsVisible(false)}
        notifications={[]} // Pass your notifications array here when you have them
      />
      {isMedium && <NotificationBar message={notification} backgroundColor={primaryColor} />}
      <FeedbackModal visible={bugReportModalVisible} onClose={handleCloseBugReportModal} />
    </>
  );
}

export default TopMenuContainer;
