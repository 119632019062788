import { useEffect, useState } from "react";
import { useGetCoursesBySchoolByQueryQuery, useSchoolsQuery } from "../../../graphql/generated/graphql";
import SearchListModal from "../../../components/common/SearchListModal/SearchListModal";
import { UsersTabModalState } from "./UsersTabContainer";
import { CourseTypes } from "../CoursesTabContainer/CoursesTable";

const AssignCourseToFacultyListContainer = ({
  visible,
  onClose,
  onAction,
}: {
  visible: boolean;
  onClose: () => void;
  onAction: (id: string) => void;
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { data: schoolsData } = useSchoolsQuery();

  const { data: coursesData, refetch: coursesRefetch } = useGetCoursesBySchoolByQueryQuery({
    variables: {
      schoolId: schoolsData?.getSchools[0].id,
      query: searchQuery,
      page: 1,
      pageSize: 5,
    },
  });

  useEffect(() => {
    coursesRefetch();
  }, [searchQuery]);

  const list =
    coursesData?.getCoursesBySchoolByQuery?.data.courses.map((course) => {
      const badge = CourseTypes[course.courseType];

      return {
        id: course.id,
        name: course.name,
        icon: badge?.icon,
        fill: badge?.color,
      };
    }) || [];

  return (
    <SearchListModal<UsersTabModalState>
      visible={visible}
      onClose={onClose}
      onAction={onAction}
      list={list}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      modalType={UsersTabModalState.ASSIGN_COURSE}
    />
  );
};

export default AssignCourseToFacultyListContainer;
