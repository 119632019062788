import React, { useState } from "react";
import { View, StyleSheet, Pressable, Text } from "react-native";
import { ListItem } from "@rneui/themed";
import { MotiView } from "moti";
import CustomText from "../../common/general/CustomText/CustomText";

function AnalyticsAccordion({ title, children, onExplorePress }) {
  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <View style={styles.container}>
      <Pressable
        style={styles.exploreButton}
        onPress={() => {
          if (onExplorePress) {
            onExplorePress();
          }
        }}>
        <CustomText
          style={{ color: "#ffffff" }}
          useTranslationText={false}
          text={"Drill Down"}
          size="xs"
          weight="bold"
        />
      </Pressable>
      <ListItem.Accordion
        content={
          <View style={styles.headerRow}>
            <View style={{ flexDirection: "column" }}>
              <CustomText useTranslationText={false} text={title} size="m" weight="bold" style={styles.header} />
              <CustomText
                useTranslationText={false}
                text="Overall Sentiment: Positive"
                size="s"
                weight="regular"
                style={[styles.header, { color: "#667085" }]}
              />
            </View>
          </View>
        }
        isExpanded={expanded}
        onPress={toggleExpanded}
        role="button"
        aria-checked={expanded}
        aria-label={`Accordion, ${expanded ? "expanded" : "collapsed"}`}
        accessibilityHint="Double tap to toggle the accordion.">
        <MotiView
          from={{ opacity: 0, translateY: -20 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{ type: "timing", duration: 100 }}>
          {children}
        </MotiView>
      </ListItem.Accordion>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 0,
    borderWidth: 1,
    borderColor: "#EAECF0",
    padding: 20,
    borderRadius: 20,
    marginBottom: 10,
  },
  headerRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between", // push left & right apart
  },
  header: {
    paddingVertical: 0,
    paddingHorizontal: 0,
    borderRadius: 5,
    left: -15,
  },
  exploreButton: {
    width: 100,
    position: "absolute",
    top: 15,
    right: 15,
    zIndex: 999,
    backgroundColor: "#3b82f6",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 12,
  },
});

export default AnalyticsAccordion;
