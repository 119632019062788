import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ProjectBlue(props) {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M31.01 27.835a5.063 5.063 0 10-8.016 0 6.152 6.152 0 00-2.121 3.235 1.687 1.687 0 001.627 2.117 1.687 1.687 0 001.63-1.254c.339-1.249 1.513-2.12 2.87-2.12 1.357 0 2.531.871 2.869 2.12a1.688 1.688 0 003.26-.867 6.145 6.145 0 00-2.12-3.23zM27 23.062a1.687 1.687 0 110 3.375 1.687 1.687 0 010-3.375zm3.375-13.5H18.758l-3.656-4.118a2.813 2.813 0 00-2.107-.944h-7.37a2.813 2.813 0 00-2.813 2.813V28.21a2.73 2.73 0 002.726 2.727h10.29a1.688 1.688 0 100-3.375h-9.64V12.938h23.625v2.25a1.687 1.687 0 103.375 0v-2.813a2.812 2.812 0 00-2.813-2.813zm-24.188 0V7.875h6.555l1.5 1.688H6.188z"
        fill="#3E68FE"
      />
    </Svg>
  );
}

export default ProjectBlue;
