import React, { useState, useRef } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import { RichEditor, RichToolbar, actions } from "react-native-pell-rich-editor";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { Switch } from "@rneui/themed";
import { Cloud, File, Remove } from "../../svgs/CustomCoursework";

const EditActivity = ({ activityType, activityData, onSave, onCancel, error, isNewActivity = false }) => {
  const [activityName, setActivityName] = useState(activityData?.title || "");
  const [description, setDescription] = useState(activityData?.description || "");
  const [objectives, setObjectives] = useState(activityData?.objectives?.map((obj) => obj.objective) || []);
  const [files, setFiles] = useState(activityData?.files || []);
  const [videoUrl, setVideoUrl] = useState(activityData?.videoUrl || "");
  const [dueDate, setDueDate] = useState(activityData?.dueDate || "");
  const [enableFileUpload, setEnableFileUpload] = useState(activityData?.enableFileUpload || false);
  const [contextText, setContextText] = useState("");
  const editorRef = useRef(null);

  const handleFileRemove = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
  };

  const handleSubmit = () => {
    const activityData = {
      title: activityName,
      description,
      objectives,
      files,
      videoUrl,
      dueDate,
      enableFileUpload,
      contextText,
      type: activityType,
    };

    onSave(activityData);
  };

  const handleKeyPress = (e) => {
    if (e.nativeEvent.key === "Enter" && !e.nativeEvent.shiftKey) {
      e.preventDefault();
      if (e.target.value.trim() !== "") {
        setObjectives([...objectives, e.target.value.trim()]);
        e.target.value = "";
      }
    }
  };

  const renderFileList = () => {
    return files.map((file, index) => (
      <View key={index} style={styles.uploadedFile}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <File />
          <CustomSpacing type="horizontal" size="s" />
          <CustomText size="m" weight="ultraBold" text={file} />
        </View>

        <CustomButton
          leftIcon={<Remove style={{ paddingRight: 4 }} />}
          text="Remove"
          size="s"
          style={{ backgroundColor: "#D0D5DD", borderRadius: 4 }}
          textStyle={{ fontSize: 14, fontWeight: 700 }}
          onPress={() => handleFileRemove(file)}
        />
      </View>
    ));
  };

  const renderSpecificFields = () => {
    switch (activityType) {
      case "Interactive Learning Experience":
        return (
          <>
            <CustomText text="Activity Description" size="m" weight="ultraBold" />
            <CustomSpacing type="vertical" size="s" />
            <RichEditor
              style={styles.editor}
              ref={editorRef}
              initialContentHTML={description}
              onChange={setDescription}
            />
            <RichToolbar
              editor={editorRef}
              actions={[actions.setBold, actions.setItalic, actions.setUnderline, actions.heading1]}
              style={styles.toolbar}
            />
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Learning Objectives" size="m" weight="ultraBold" />
            <CustomText
              text="Define the specific goals or skills students should achieve through this activity. These guide the AI’s teaching focus."
              style={styles.subText}
              useTranslationText={false}
            />
            <CustomSpacing type="vertical" size="s" />
            <CustomTextInput
              placeholder="Add an objective and press enter"
              maxLength={100}
              onKeyPress={handleKeyPress}
            />
            <CustomSpacing type="vertical" size="m" />
            <View style={styles.objectivesList}>
              {objectives.map((objective, index) => (
                <View key={index} style={styles.objectiveItem}>
                  <CustomText text={objective} size="s" useTranslationText={false} style={styles.objectiveText} />
                  <CustomButton
                    styleType="transparent"
                    leftIcon={<Remove />}
                    size="s"
                    onPress={() => setObjectives(objectives.filter((_, i) => i !== index))}
                  />
                </View>
              ))}
            </View>
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Contextual Alignment" size="m" weight="ultraBold" />
            <CustomText
              text="Add additional context and enrich the AI’s teaching focus for this activity."
              style={styles.subText}
              useTranslationText={false}
            />
            {/*<CustomSpacing type="vertical" size="xl" />
            <View style={styles.fileUpload}>
              <Cloud />
              <CustomText text="Drag & drop or browse" size="m" style={{ color: "#344054" }} />
              <CustomText text="Max file size: 5mb" size="m" style={{ color: "#5177FE" }} />
            </View>
            {renderFileList()}
            */}
            <CustomSpacing type="vertical" size="l" />
            <CustomTextInput
              placeholder="Add further Contextual Alignment"
              value={contextText}
              onChangeText={setContextText}
              numberOfLines={4}
              multiline
            />
          </>
        );

      case "Resource":
        return (
          <>
            <CustomText text="Activity Description" size="m" weight="ultraBold" />
            <CustomSpacing type="vertical" size="s" />
            <RichEditor
              style={styles.editor}
              ref={editorRef}
              initialContentHTML={description}
              onChange={setDescription}
            />
            <RichToolbar
              editor={editorRef}
              actions={[actions.setBold, actions.setItalic, actions.setUnderline, actions.heading1]}
              style={styles.toolbar}
            />
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="File Upload" size="m" weight="ultraBold" />
            <CustomText style={styles.subText} text="Explanation about resource upload should go here." />
            <CustomSpacing type="vertical" size="s" />
            <View style={styles.fileUpload}>
              <Cloud />
              <CustomText text="Drag & drop or browse" size="m" style={{ color: "#344054" }} />
              <CustomText text="Max file size: 5mb" size="m" style={{ color: "#5177FE" }} />
            </View>
            {renderFileList()}
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Video URL" size="m" weight="ultraBold" />
            <CustomText style={styles.subText} text="Paste either a YouTube or Vimeo link here" />
            <CustomSpacing type="vertical" size="s" />
            <CustomTextInput
              placeholder="Paste either a YouTube or Vimeo link here"
              value={videoUrl}
              onChangeText={setVideoUrl}
            />
          </>
        );

      case "Assignment":
        return (
          <>
            <CustomText text="Due Date" size="m" weight="ultraBold" />
            <CustomSpacing type="vertical" size="s" />
            <CustomTextInput placeholder="Add a due date" value={dueDate} onChangeText={setDueDate} />
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Activity Instructions" size="m" weight="ultraBold" />
            <CustomSpacing type="vertical" size="s" />
            <RichEditor
              style={styles.editor}
              ref={editorRef}
              initialContentHTML={description}
              onChange={setDescription}
            />
            <RichToolbar
              editor={editorRef}
              actions={[actions.setBold, actions.setItalic, actions.setUnderline, actions.heading1]}
              style={styles.toolbar}
            />
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="File Upload" size="m" weight="ultraBold" />
            <CustomText style={styles.subText} text="Explanation about resource upload should go here." />
            <CustomSpacing type="vertical" size="s" />
            <View style={styles.fileUpload}>
              <Cloud />
              <CustomText text="Drag & drop or browse" size="m" style={{ color: "#344054" }} />
              <CustomText text="Max file size: 5mb" size="m" style={{ color: "#5177FE" }} />
            </View>
            {renderFileList()}
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Video URL" size="m" weight="ultraBold" />
            <CustomText style={styles.subText} text="Paste either a YouTube or Vimeo link here" />
            <CustomSpacing type="vertical" size="s" />
            <CustomTextInput
              placeholder="Paste either a YouTube or Vimeo link here"
              value={videoUrl}
              onChangeText={setVideoUrl}
            />
            <CustomSpacing type="vertical" size="xl" />
            <View style={styles.toggleContainer}>
              <CustomText text="Enable student file upload" size="m" weight="ultraBold" />
              <CustomSpacing type="horizontal" size="s" />
              <Switch value={enableFileUpload} onValueChange={setEnableFileUpload} />
            </View>
          </>
        );

      case "Project":
        return (
          <>
            <CustomText text="Activity Instructions" size="m" weight="ultraBold" />
            <CustomSpacing type="vertical" size="s" />
            <RichEditor
              style={styles.editor}
              ref={editorRef}
              initialContentHTML={description}
              onChange={setDescription}
            />
            <RichToolbar
              editor={editorRef}
              actions={[actions.setBold, actions.setItalic, actions.setUnderline, actions.heading1]}
              style={styles.toolbar}
            />
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="File Upload" size="m" weight="ultraBold" />
            <CustomText style={styles.subText} text="Explanation about resource upload should go here." />
            <CustomSpacing type="vertical" size="s" />
            <View style={styles.fileUpload}>
              <Cloud />
              <CustomText text="Drag & drop or browse" size="m" style={{ color: "#344054" }} />
              <CustomText text="Max file size: 5mb" size="m" style={{ color: "#5177FE" }} />
            </View>
            {renderFileList()}
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Video URL" size="m" weight="ultraBold" />
            <CustomText style={styles.subText} text="Paste either a YouTube or Vimeo link here" />
            <CustomSpacing type="vertical" size="s" />
            <CustomTextInput
              placeholder="Paste either a YouTube or Vimeo link here"
              value={videoUrl}
              onChangeText={setVideoUrl}
            />
            <CustomSpacing type="vertical" size="xl" />
            <View style={styles.toggleContainer}>
              <CustomText text="Enable student file upload" size="m" weight="ultraBold" />
              <CustomSpacing type="horizontal" size="s" />
              <Switch value={enableFileUpload} onValueChange={setEnableFileUpload} />
            </View>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.header}>
        <CustomText
          text={`${isNewActivity ? "Create" : "Edit"} ${activityType}`}
          size="xl"
          weight="bold"
          style={{ padding: 32 }}
          useTranslationText={false}
        />
      </View>
      <View style={styles.content}>
        <CustomSpacing type="vertical" size="xl" />
        <CustomText text="Activity Name" size="m" weight="ultraBold" />
        <CustomSpacing type="vertical" size="s" />
        <CustomTextInput placeholder="Enter activity name" value={activityName} onChangeText={setActivityName} />
        <CustomSpacing type="vertical" size="xl" />
        {renderSpecificFields()}
        {error && <CustomText text={error} size="m" useTranslationText={false} style={styles.errorText} />}
        <View style={styles.buttons}>
          <CustomButton
            text="Cancel"
            styleType="primaryLight"
            size="s"
            textStyle={{ fontSize: 14, weight: 700 }}
            style={styles.cancelButton}
            onPress={onCancel}
          />
          <CustomSpacing type="horizontal" size="s" />
          <CustomButton
            text={isNewActivity ? "Create" : "Save"}
            styleType="primary"
            size="s"
            textStyle={{ fontSize: 14, weight: 700 }}
            onPress={handleSubmit}
          />
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    backgroundColor: "#F2F4F7",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  content: {
    paddingHorizontal: 32,
  },
  objectivesList: {
    marginTop: 8,
  },
  objectiveItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 8,
    paddingHorizontal: 16,
    backgroundColor: "#E6EBFF",
    borderRadius: 8,
    marginBottom: 4,
  },
  objectiveText: {
    flex: 1,
    fontSize: 14,
    fontWeight: 400,
  },
  fileUpload: {
    borderWidth: 1,
    borderColor: "#6788FE",
    borderRadius: 8,
    borderStyle: "dashed",
    padding: 16,
    alignItems: "center",
    backgroundColor: "#F2F5FF",
  },
  uploadedFile: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 12,
    backgroundColor: "#F3F4F6",
    borderRadius: 8,
    marginBottom: 16,
    marginTop: 16,
  },
  editor: {
    flex: 1,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 4,
  },
  toolbar: {
    backgroundColor: "#f1f1f1",
    borderColor: "#ccc",
    borderTopWidth: 1,
  },
  subText: {
    color: "#667085",
    fontSize: 14,
    fontWeight: "400",
  },
  toggleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  cancelButton: {
    borderColor: "#3E68FE",
    borderWidth: 2,
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 24,
    paddingBottom: 40,
  },
  errorText: {
    color: "#FF0000",
  },
});

export default EditActivity;
