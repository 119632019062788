import React, { useState, useEffect, useRef } from "react";
import { View, StyleSheet, Text, TextInput } from "react-native";
import { usePostHog } from "posthog-js/react";
import { useRoute } from "@react-navigation/native";
import { useGetCoursesAnalyticsBySchoolQuery, useSchoolsQuery } from "../graphql/generated/graphql";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import BubbleChartContainer from "../containers/analytics/BubbleChartContainer/BubbleChartContainer";
import AccordionAnalyticsContainer from "../containers/analytics/AccordionAnalyticsContainer.js/AccordionAnalyticsContainer";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import { MotiView } from "moti";

const computeGlobalExtremes = (data) => {
  let maxAbsX = 0;
  let maxAbsY = 0;

  const processItem = (item) => {
    const s = item.success_points || 0;
    const c = item.confusion_points || 0;
    const q = item.questions_asked || 0;
    const a = item.active_interaction_count || 0;
    const d = item.distractions || 0;

    const xVal = s - c;
    const yVal = q + a - d;

    maxAbsX = Math.max(maxAbsX, Math.abs(xVal));
    maxAbsY = Math.max(maxAbsY, Math.abs(yVal));

    if (item.details) {
      ["modules", "activities", "objectives"].forEach((key) => {
        if (item.details[key]) {
          item.details[key].forEach(processItem);
        }
      });
    }
  };

  data.forEach(processItem);
  return { maxAbsX, maxAbsY };
};

const mapDataWithGlobalScale = (data, level, globalScale) => {
  const { maxAbsX, maxAbsY } = globalScale;

  return data.map((item, index) => {
    const s = item.success_points || 0;
    const c = item.confusion_points || 0;
    const q = item.questions_asked || 0;
    const a = item.active_interaction_count || 0;
    const d = item.distractions || 0;

    const xVal = ((s - c) / (maxAbsX || 1)) * 10;
    const yVal = ((q + a - d) / (maxAbsY || 1)) * 10;

    const sentiment = item.sentiment || "none";
    const enrollment = item.enrollment || 1;

    return {
      x: xVal,
      y: yVal,
      size: Math.max((enrollment / 300) * 30, 10),
      color:
        sentiment === "positive"
          ? "#85E139"
          : sentiment === "mixed"
            ? "#0084FF"
            : sentiment === "negative"
              ? "#F63D68"
              : "#0084FF",
      label: item.label || `Item ${index + 1}`,
      details: item.details || null,
      level: level,
    };
  });
};

const AnalyticsView = () => {
  const { data: schoolsData, loading: schoolDataLoading, error: schoolDataError } = useSchoolsQuery();

  const schoolId = schoolsData?.getSchools[0]?.id;

  const { data: coursesData, loading: coursesLoading } = useGetCoursesAnalyticsBySchoolQuery({
    variables: { schoolId: schoolId },
  });

  const [breadcrumb, setBreadcrumb] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [globalScale, setGlobalScale] = useState({ maxAbsX: 0, maxAbsY: 0 });
  const [searchText, setSearchText] = useState("");
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  // Initialize breadcrumb and globalScale
  useEffect(() => {
    if (!coursesLoading && coursesData?.getCoursesAnalyticsBySchool?.data) {
      const data = coursesData.getCoursesAnalyticsBySchool.data;
      const scale = computeGlobalExtremes(data);

      setBreadcrumb([{ label: "Courses", level: "course", data }]);
      setCurrentData(mapDataWithGlobalScale(data, "course", scale));
      console.log("Global scale", mapDataWithGlobalScale(data, "course", scale));
      setGlobalScale(scale);
    }
  }, [coursesData, coursesLoading]);

  // Handle search filtering
  useEffect(() => {
    if (!breadcrumb.length) return;

    const currentLevelData = breadcrumb[breadcrumb.length - 1].data;
    const filteredData = currentLevelData.filter((item) => item.label.toLowerCase().includes(searchText.toLowerCase()));

    setCurrentData(mapDataWithGlobalScale(filteredData, breadcrumb[breadcrumb.length - 1].level, globalScale));
  }, [searchText, breadcrumb, globalScale]);

  // Resize observer for container width
  useEffect(() => {
    if (containerRef.current) {
      const observer = new ResizeObserver((entries) => {
        setContainerWidth(entries[0].contentRect.width);
      });
      observer.observe(containerRef.current);
      return () => observer.disconnect();
    }
  }, []);

  function handleDrillDown(clickedItem) {
    const detailKeyMapping = {
      course: "modules",
      module: "activities",
      activity: "objectives",
    };

    const nextLevelMapping = {
      course: "module",
      module: "activity",
      activity: "objective",
    };

    const detailKey = detailKeyMapping[clickedItem.level];
    const nextData = clickedItem.details?.[detailKey];

    if (!nextData || !nextData.length) {
      console.warn(`No data available for ${detailKey}`);
      return;
    }

    setBreadcrumb((prev) => [
      ...prev,
      {
        label: clickedItem.label,
        level: nextLevelMapping[clickedItem.level],
        data: nextData,
      },
    ]);
    setCurrentData(mapDataWithGlobalScale(nextData, nextLevelMapping[clickedItem.level], globalScale));
  }

  const handleBreadcrumbClick = (index) => {
    const selectedBreadcrumb = breadcrumb[index];
    setBreadcrumb(breadcrumb.slice(0, index + 1));
    setCurrentData(mapDataWithGlobalScale(selectedBreadcrumb.data, selectedBreadcrumb.level, globalScale));
  };

  return (
    <NoChatBarLayout>
      <MotiView
        from={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <CustomText useTranslationText={false} text="Analytics" size="xl" weight="ultraBold" />
      </MotiView>
      <CustomSpacing type="vertical" size="m" />
      <View style={styles.breadcrumb}>
        {breadcrumb.map((crumb, index) => (
          <Text
            key={index}
            style={[styles.breadcrumbItem, index === breadcrumb.length - 1 && styles.activeBreadcrumb]}
            onPress={() => handleBreadcrumbClick(index)}>
            {crumb.label}
            {index < breadcrumb.length - 1 && " > "}
          </Text>
        ))}
      </View>
      <TextInput
        style={styles.searchInput}
        placeholder="Search courses..."
        value={searchText}
        onChangeText={setSearchText}
      />
      <View style={styles.container} ref={containerRef}>
        <BubbleChartContainer
          data={currentData}
          onDrillDown={handleDrillDown}
          containerWidth={Math.max(containerWidth * 0.7 - 40, 450)}
        />
        <AccordionAnalyticsContainer data={currentData} onListDrillDown={handleDrillDown} breadcrumb={breadcrumb} />
      </View>
    </NoChatBarLayout>
  );
};

const styles = StyleSheet.create({
  breadcrumb: {
    flexDirection: "row",
    marginBottom: 10,
  },
  breadcrumbItem: {
    color: "blue",
    textDecorationLine: "underline",
    cursor: "pointer",
  },
  activeBreadcrumb: {
    color: "black",
    textDecorationLine: "none",
  },
  searchInput: {
    borderWidth: 1,
    borderColor: "#ddd",
    padding: 10,
    marginBottom: 10,
    borderRadius: 5,
  },
  container: {
    flex: 1,
    width: "100%",
  },
});

export default AnalyticsView;
