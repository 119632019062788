import React from "react";
import { StyleSheet, View } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";

interface NewCustomAxioActionModalProps {
  visible: boolean;
  onClose?: () => void;
  onAction?: () => void;
  modalTexts: {
    title: string;
    ariaLabel: string;
    warningText: string;
    actionButtonText: string;
  };
  contextualText?: string;
  children?: React.ReactNode;
}

const NewCustomAxioActionModal = ({
  visible,
  onClose,
  onAction,
  modalTexts,
  contextualText,
  children,
}: NewCustomAxioActionModalProps) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title={modalTexts.title}
      exitFill="#6B7280"
      onShow={() => {}}
      ariaLabel={modalTexts.ariaLabel}
      style={{ width: isMedium ? "50%" : "90%" }}>
      <View style={styles.container}>
        <CustomText text={modalTexts.warningText} size="s" style={styles.warningText} />
        {!!contextualText && (
          <CustomText text={contextualText} size="s" style={styles.warningText} useTranslationText={false} />
        )}
        {children}
        <View style={styles.buttonContainer}>
          {onClose && (
            <CustomButton
              text={onAction ? "Cancel" : "Close"}
              onPress={onClose}
              styleType="secondary"
              size="m"
              style={onAction ? styles.cancelButton : styles.actionButton}
              bold={false}
              leftIcon={null}
              rightIcon={null}
              disabled={false}
              textProps={{}}
              textStyle={onAction ? styles.cancelButtonText : styles.actionButtonText}
            />
          )}
          {onAction && (
            <CustomButton
              text={modalTexts.actionButtonText}
              onPress={onAction}
              styleType="danger"
              size="m"
              style={styles.actionButton}
              bold={false}
              leftIcon={null}
              rightIcon={null}
              textStyle={styles.actionButtonText}
              disabled={false}
              textProps={{}}
            />
          )}
        </View>
      </View>
    </CustomModal>
  );
};

export default NewCustomAxioActionModal;

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  warningText: {
    color: "#4B5563",
    marginBottom: 24,
    lineHeight: 24,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 12,
  },
  cancelButton: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  actionButton: {
    backgroundColor: "#4F46E5",
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  cancelButtonText: {
    color: "#4B5563",
  },
  actionButtonText: {
    color: "#FFFFFF",
  },
});
