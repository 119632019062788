import React from "react";
import { StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";

const AddModule = ({ addButtonClick, cancelButtonClick, onChangeModuleName, setOrder, hasOrder }) => {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <CustomText style={styles.title} text="Add Module" />
      </View>

      <View style={styles.content}>
        <CustomText style={styles.label} text="Module Name" />
        <CustomTextInput
          style={styles.input}
          onChangeText={onChangeModuleName}
          accessibilityHint="Enter your module name"
        />
        {hasOrder && (
          <>
            <CustomSpacing type="vertical" size="m" />
            <CustomText style={styles.label} text="Order" />
            <CustomTextInput
              style={styles.input}
              placeholder="Ex: 9"
              required
              onChangeText={(text) => setOrder(parseInt(text, 10))}
            />
            <CustomText
              style={styles.helperText}
              text="Leave empty to place at the bottom of the list"
              useTranslationText={false}
            />
          </>
        )}
        <CustomSpacing type="vertical" size="l" />
        <View style={styles.buttonContainer}>
          <CustomButton
            text="Cancel"
            styleType="primaryLight"
            style={styles.cancelButton}
            size="s"
            onPress={cancelButtonClick}
            bold="true"
            aria-label="Cancel"
          />
          <CustomSpacing type="horizontal" size="s" />
          <CustomButton
            text="Save"
            onPress={addButtonClick}
            styleType="primary"
            size="s"
            bold="true"
            aria-label="Add Module"
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    overflow: "hidden",
    width: "100%",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    backgroundColor: "#F2F4F7",
    width: "100%",
    alignSelf: "stretch",
  },
  title: {
    fontSize: 20,
    fontWeight: "600",
    color: "#1A1A1A",
  },
  content: {
    padding: 24,
  },
  label: {
    fontSize: 16,
    fontWeight: "600",
    color: "#1A1A1A",
    marginBottom: 8,
  },
  input: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#E5E5E5",
    borderRadius: 8,
    padding: 12,
    fontSize: 16,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  cancelButton: {
    borderColor: "#3E68FE",
    borderWidth: 2,
  },
  helperText: {
    fontSize: 14,
    color: "#667085",
    marginTop: 4,
  },
});

export default AddModule;
