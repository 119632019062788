import { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { View, StyleSheet } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { MotiView } from "moti";
import TabNavigator from "../components/common/general/TabNavigator/TabNavigator";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import { useAppConfig } from "../AppConfigProvider";
import UsersTabContainer from "../containers/settings/UsersTabContainer/UsersTabContainer";
import CoursesTabContainer from "../containers/settings/CoursesTabContainer/CoursesTabContainer";
import IntegrationsTabContainer from "../containers/settings/IntegrationsTabContainer/IntegrationsTabContainer";
import { useAuth } from "../hooks/useAuth";
import ProfileTabContainer from "../containers/settings/ProfileTabContainer/ProfileTabContainer";
import FacultyProfileTabContainer from "../containers/settings/FacultyProfileTabContainer/FacultyProfileTabContainer";
import { RolePermissions } from "../constants/rolesAndPermissions";

function SettingsView() {
  const posthog = usePostHog();
  const { isMedium } = useResponsiveScreen();
  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;
  const { isAxioOffering } = useAppConfig();

  const [activeTab, setActiveTab] = useState("profile");

  const { rolePermissions } = useAuth();

  const tabs = [{ key: "profile", title: "Profile" }];
  if (rolePermissions.includes(RolePermissions.manageFaculty)) {
    tabs.push({ key: "users", title: "Users" });
  }
  if (rolePermissions.includes(RolePermissions.manageFaculty)) {
    tabs.push({ key: "courses", title: "Courses" });
  }

  useEffect(() => {
    posthog?.capture("Settings Page Visited");
  }, []);

  return (
    <NoChatBarLayout>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={10}
        transition={{
          all: { type: "timing", duration: 500 },
        }}>
        <View style={styles.container}>
          <View style={styles.tabContainer}>
            <CustomText
              useTranslationText={false}
              text={"Settings"}
              size={isMedium ? "l" : "xs"}
              textType="display"
              weight="ultraBold"
              role="heading"
              aria-level="1"
              style={undefined}
            />
          </View>
        </View>
      </MotiView>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={500}
        transition={{
          all: { type: "timing", duration: 600 },
        }}>
        <CustomSpacing type="vertical" size="s" />
        {isAxioOffering ? (
          <TabNavigator
            tabData={tabs}
            activeTab={activeTab}
            onTabPress={(key) => {
              setActiveTab(key);
            }}
            activeTabColor={primaryColor}
            rightButtonComponent={undefined}
          />
        ) : null}
        <CustomSpacing type="vertical" size="l" />
      </MotiView>
      {activeTab === "profile" ? (
        rolePermissions.includes(RolePermissions.viewSchoolAnalytics) ? (
          <FacultyProfileTabContainer />
        ) : (
          <ProfileTabContainer />
        )
      ) : null}
      {activeTab === "users" && rolePermissions.includes(RolePermissions.manageFaculty) ? <UsersTabContainer customColor={primaryColor} /> : null}
      {activeTab === "courses" && rolePermissions.includes(RolePermissions.manageFaculty) ? (
        <CoursesTabContainer customColor={primaryColor} />
      ) : null}
      {activeTab === "integrations" ? <IntegrationsTabContainer /> : null}
    </NoChatBarLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  tabContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
});

export default SettingsView;
