import React, { useEffect, useState } from "react";
import { VictoryChart, VictoryScatter, VictoryTheme, VictoryAxis } from "victory-native";
import { View, Text } from "react-native";

/**
 * A specialized bubble chart container for top-level "student" data,
 * with Risk on the X-axis and Performance on the Y-axis.
 */
const StudentBubbleChartContainer = ({ containerWidth, data, onDrillDown }) => {
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  // Domain: you might want to dynamically compute min/max based on the actual risk/performance values.
  // For example, let's do a quick approach: find min & max from the data.
  const allX = filteredData.map((d) => d.x);
  const allY = filteredData.map((d) => d.y);
  const minX = Math.min(...allX, 0);
  const maxX = Math.max(...allX, 10);
  const minY = Math.min(...allY, 0);
  const maxY = Math.max(...allY, 10);

  const domain = { x: [minX - 1, maxX + 1], y: [minY - 1, maxY + 1] };

  return (
    <View style={{ flexDirection: "row", padding: 20 }}>
      {/* Left side: Chart */}
      <View style={{ flex: 2, position: "relative" }}>
        <View
          style={{
            position: "absolute",
            top: 50,
            left: 50,
            right: 50,
            bottom: 50,
            pointerEvents: "none",
          }}>
          {/* top-left area: High Performance, Low Risk */}
          <View
            style={{
              position: "absolute",
              backgroundColor: "#E6FFFA", // Light Green
              top: 0,
              left: 0,
              width: "50%",
              height: "50%",
            }}
          />
          {/* top-right area: High Performance, High Risk */}
          <View
            style={{
              position: "absolute",
              backgroundColor: "#FFF7EB", // Yellow
              top: 0,
              right: 0,
              width: "50%",
              height: "50%",
            }}
          />
          {/* bottom-left area: Low Performance, Low Risk */}
          <View
            style={{
              position: "absolute",
              backgroundColor: "#FAF4FF", // Light Blue
              bottom: 0,
              left: 0,
              width: "50%",
              height: "50%",
            }}
          />
          {/* bottom-right area: Low Performance, High Risk */}
          <View
            style={{
              position: "absolute",
              backgroundColor: "#FFEBE6", // Light Red
              bottom: 0,
              right: 0,
              width: "50%",
              height: "50%",
            }}
          />
        </View>

        <VictoryChart
          height={550}
          width={containerWidth}
          domain={domain}
          theme={VictoryTheme.clean}
          padding={{ top: 50, bottom: 50, left: 50, right: 50 }}>
          <VictoryAxis
            style={{
              axis: { stroke: "#98A2B3", strokeWidth: 1 },
              tickLabels: { fontSize: 10 },
            }}
            label="Risk →"
          />
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: "#98A2B3", strokeWidth: 1 },
              tickLabels: { fontSize: 10 },
            }}
            label="Performance ↑"
          />

          <VictoryScatter
            data={filteredData.filter((d) => !isNaN(d.x) && !isNaN(d.y))}
            size={({ datum }) => datum.size}
            style={{
              data: {
                fill: ({ datum }) => datum.color,
                opacity: 0.85,
                stroke: "#fff",
                strokeWidth: 2,
              },
            }}
            labels={({ datum }) => datum.label}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: (evt, props) => {
                    const clickedData = props.datum;
                    if (onDrillDown) {
                      onDrillDown(clickedData);
                    }
                    return [];
                  },
                },
              },
            ]}
          />
        </VictoryChart>
      </View>

      {/* Right side: Some legend or filters as needed */}
      <View style={{ flex: 1, paddingLeft: 20 }}>
        <Text style={{ fontWeight: "bold", marginBottom: 10 }}>Risk vs. Performance</Text>
        <Text style={{ marginBottom: 5 }}>
          X-axis = Risk = Confusion + Distractions (plus penalty if negative sentiment)
        </Text>
        <Text style={{ marginBottom: 5 }}>Y-axis = Performance = Success + Active Interaction + 0.5 * Questions</Text>
        <View style={{ marginTop: 10 }}>
          <Text style={{ fontSize: 12, fontWeight: "bold", marginBottom: 5 }}>Legend (Colors):</Text>
          <View style={{ flexDirection: "row", marginBottom: 5 }}>
            <View style={{ width: 15, height: 15, backgroundColor: "#4ECDC4", marginRight: 5 }} />
            <Text>Positive</Text>
          </View>
          <View style={{ flexDirection: "row", marginBottom: 5 }}>
            <View style={{ width: 15, height: 15, backgroundColor: "#FFDD57", marginRight: 5 }} />
            <Text>Mixed</Text>
          </View>
          <View style={{ flexDirection: "row", marginBottom: 5 }}>
            <View style={{ width: 15, height: 15, backgroundColor: "#FF6B6B", marginRight: 5 }} />
            <Text>Negative</Text>
          </View>
          <View style={{ flexDirection: "row", marginBottom: 5 }}>
            <View style={{ width: 15, height: 15, backgroundColor: "#0084FF", marginRight: 5 }} />
            <Text>None</Text>
          </View>
        </View>
        {/* Example filter UI */}
        <Text style={{ marginBottom: 5, fontWeight: "bold" }}>Filter Data:</Text>
        <Text
          style={{ color: "blue", textDecorationLine: "underline", marginBottom: 5 }}
          onPress={() => setFilteredData(data.filter((d) => d.color === "#4ECDC4"))}>
          Show At Risk
        </Text>
        <Text style={{ color: "blue", textDecorationLine: "underline" }} onPress={() => setFilteredData(data)}>
          Show All
        </Text>
      </View>
    </View>
  );
};

export default StudentBubbleChartContainer;
