import { useEffect, useState } from "react";
import { useGetCoursesByFacultyQuery } from "../../../graphql/generated/graphql";
import SearchListModal from "../../../components/common/SearchListModal/SearchListModal";
import { UsersTabModalState } from "./UsersTabContainer";

const RemoveCourseFromFacultyListContainer = ({
  visible,
  onClose,
  onAction,
  userId,
}: {
  visible: boolean;
  onClose: () => void;
  onAction: (id: string) => void;
  userId: string;
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { data: coursesData, refetch: coursesRefetch } = useGetCoursesByFacultyQuery({
    variables: {
      userId: userId,
      query: searchQuery,
    },
  });

  useEffect(() => {
    coursesRefetch();
  }, [searchQuery, userId]);

  return (
    <SearchListModal<UsersTabModalState>
      visible={visible}
      onClose={onClose}
      onAction={onAction}
      list={
        coursesData?.getCoursesByFaculty?.data.map((course) => ({
          id: course.id,
          name: course.name,
        })) || []
      }
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      modalType={UsersTabModalState.REMOVE_COURSE}
    />
  );
};

export default RemoveCourseFromFacultyListContainer;
