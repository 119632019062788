import CustomText from "../../common/general/CustomText/CustomText";
import { ListItem } from "@rneui/themed";
import { StyleSheet, Pressable, View } from "react-native";

import { Sparkle, Document, Link, Project, Assessment } from "../../svgs/CustomCoursework";

const getIcon = (type) => {
  switch (type) {
    case "Interactive Learning Experience":
      return Sparkle;
    case "Resource":
      return Link;
    case "Assignment":
      return Document;
    case "Project":
      return Project;
    case "Lesson Assessment":
      return Assessment;
    default:
      return Sparkle;
  }
};

const CustomCourseworkMenuItem = ({ onItemPress, name, isSelected, isLast, type }) => {
  const IconComponent = type ? getIcon(type) : null;

  return (
    <ListItem.Content
      style={[
        styles.item,
        {
          marginBottom: isLast ? 10 : 0,
        },
      ]}>
      <Pressable onPress={onItemPress} style={styles.pressableContainer}>
        {IconComponent && (
          <View style={styles.iconContainer}>
            <IconComponent />
          </View>
        )}
        <CustomText
          useTranslationText={false}
          text={name}
          weight={isSelected ? "bold" : "400"}
          numberOfLines={2}
          ellipsizeMode="tail"
          size="m"
        />
      </Pressable>
    </ListItem.Content>
  );
};

const styles = StyleSheet.create({
  item: {
    padding: 12,
    paddingLeft: 30,
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexShrink: 1,
    borderRadius: 4,
  },
  pressableContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  iconContainer: {
    marginRight: 12,
  },
});

export default CustomCourseworkMenuItem;
