import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../components/common/general/CustomText/CustomText";
import CustomButton from "../../components/common/general/CustomButton/CustomButton";
import CustomSpacing from "../../components/common/layout/CustomSpacing/CustomSpacing";
import { EditTaskBlue, TrashCanBlue } from "../../components/svgs/common";
import { WebView } from "react-native-webview";

function ActivityContainer({ activity, onEdit, onDelete }) {
  console.log("acticity container", activity);
  console.log("activity container LO", activity.objectives);
  console.log("activity container description", activity.description);
  if (!activity) {
    return (
      <View style={styles.emptyContainer}>
        <CustomText text="No activity selected" style={styles.emptyText} />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.activityHeader}>
        <View style={{ flexDirection: "column" }}>
          <CustomText text={activity.type} size="s" weight="medium" style={{ color: "#667085" }} />
          <CustomText
            text={activity.title}
            weight="bold"
            size="xl"
            useTranslationText={false}
            style={{ paddingBottom: 20 }}
          />
        </View>
        <View style={styles.buttonContainer}>
          {/* <CustomButton
            text="Edit"
            styleType="primaryLight"
            onPress={onEdit}
            size="s"
            style={{ borderColor: "#05060f1a", borderWidth: 1, height: 36 }}
            leftIcon={<EditTaskBlue width={16} height={16} style={{ marginRight: 4 }} />}
            textStyle={{ fontSize: 14, fontWeight: 700 }}
          /> */}
          <CustomSpacing type="horizontal" size="m" />
          <CustomButton
            text="Delete"
            styleType="primaryLight"
            onPress={onDelete}
            size="s"
            leftIcon={<TrashCanBlue width={16} height={16} />}
            style={{ borderColor: "#05060f1a", borderWidth: 1, height: 36 }}
            textStyle={{ fontSize: 14, fontWeight: 700 }}
          />
        </View>
      </View>

      <CustomSpacing type="vertical" size="m" />

      {/* Activity Instructions */}
      <CustomText text="Activity Instructions" weight="ultraBold" size="m" />
      <CustomSpacing type="vertical" size="xl" />
      <View style={{ flex: 1 }}>
        <WebView source={{ html: activity?.description || "<p>No content available</p>" }} originWhitelist={["*"]} />
      </View>

      <CustomSpacing type="vertical" size="xl" />

      {/* Learning Objectives */}
      <CustomText text="Learning Objectives" weight="ultraBold" size="m" />
      <CustomSpacing type="vertical" size="xl" />
      {activity.objectives?.length > 0 ? (
        activity.objectives.map((obj, index) => (
          <View key={obj.id} style={styles.objectiveContainer}>
            <CustomText
              text={`${index + 1}. ${obj.objective}`}
              size="m"
              style={styles.text}
              useTranslationText={false}
            />
            <CustomSpacing type="vertical" size="s" />
          </View>
        ))
      ) : (
        <CustomText text="No learning objectives available" size="m" style={styles.text} useTranslationText={false} />
      )}
      <CustomSpacing type="vertical" size="xl" />

      {/* Contextual Alignment */}
      <CustomText text="Contextual Alignment" weight="ultraBold" size="m" />
      <CustomSpacing type="vertical" size="xl" />
      <CustomText text={activity.contextualAlignment} size="m" style={styles.text} useTranslationText={false} />
      <CustomSpacing type="vertical" size="xl" />

      {/* Attached File */}
      {activity.file && (
        <View style={styles.fileContainer}>
          <CustomText text={activity.file.name} weight="bold" style={styles.fileText} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  activityHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#E5E7EB",
    justifyContent: "space-between",
    marginVertical: 10,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
  },
  text: {
    color: "#344054",
  },
  fileContainer: {
    padding: 10,
    backgroundColor: "#F9FAFB",
    borderRadius: 8,
  },
  fileText: {
    color: "#6B7280",
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F9FAFB",
  },
  emptyText: {
    color: "#6B7280",
  },
  objectiveContainer: {
    paddingLeft: 8,
    marginBottom: 8,
  },
});

export default ActivityContainer;
