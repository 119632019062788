import React, { useState } from "react";
import { StyleSheet, View, Pressable } from "react-native";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";
import NewCustomAxioActionModal from "./NewCustomAxioActionModal";

const inviteSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().email("Invalid email address"),
  role: z.enum(["School Admin", "Faculty"]),
});

type InviteFormData = z.infer<typeof inviteSchema>;

interface InviteFacultyModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (formData: InviteFormData) => Promise<boolean>;
}

const roleOptions = [
  {
    value: "School Admin",
    label: "Admin",
    description:
      "Admins can invite and remove users, manage all courses, assign and remove faculty, and view analytics for all courses and students.",
  },
  {
    value: "Faculty",
    label: "Faculty",
    description: "Faculty can edit their courses and view analytics for their assigned courses and students.",
  },
];

const modals = {
  inviteConfirmation: {
    title: "Success!",
    ariaLabel: "Invite Confirmation Modal",
    warningText: "An invitation email has been sent to the following email address.",
    actionButtonText: "OK",
  },
  inviteError: {
    title: "Error!",
    ariaLabel: "Invite Error Modal",
    warningText: "An error occurred while sending the invitation email. Please try again later.",
    actionButtonText: "CLOSE",
  },
};

export enum InviteFacultyModalState {
  CLOSED = "CLOSED",
  INVITE_CONFIRMATION = "INVITE_CONFIRMATION",
  INVITE_ERROR = "INVITE_ERROR",
}

const InviteFacultyModal: React.FC<InviteFacultyModalProps> = ({ visible, onClose, onSubmit }) => {
  const [modalState, setModalState] = useState({
    state: InviteFacultyModalState.CLOSED,
  });
  const [emailValue, setEmailValue] = useState("");

  const { isMedium } = useResponsiveScreen();
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<InviteFormData>({
    resolver: zodResolver(inviteSchema),
    defaultValues: {
      name: "",
      email: "",
      role: "Faculty",
    },
  });

  const handleFormSubmit = React.useCallback(
    async (formData: InviteFormData) => {
      console.log("formData", formData);
      const result = await onSubmit(formData);
      setEmailValue(formData.email);
      reset();
      onClose();
      if (result) {
        setModalState({ state: InviteFacultyModalState.INVITE_CONFIRMATION });
      } else {
        setModalState({ state: InviteFacultyModalState.INVITE_ERROR });
      }
    },
    [onSubmit, onClose]
  );

  return (
    <>
      <CustomModal
        visible={visible}
        onRequestClose={onClose}
        showCloseButton
        title="Invite a Member"
        exitFill="#6B7280"
        ariaLabel="Invite faculty modal"
        style={{ width: isMedium ? "50%" : "90%" }}
        onShow={() => {}}>
        <View style={styles.container}>
          <View style={styles.formGroup}>
            <CustomText text="Name" size="s" style={styles.label} />
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value } }) => (
                <CustomTextInput
                  value={value}
                  onChangeText={onChange}
                  placeholder="Bob Jones"
                  error={errors.name?.message}
                  style={styles.input}
                />
              )}
            />
          </View>

          <View style={styles.formGroup}>
            <CustomText text="Email Address" size="s" style={styles.label} />
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => (
                <CustomTextInput
                  value={value}
                  onChangeText={onChange}
                  placeholder="jamie@school.org"
                  error={errors.email?.message}
                  style={styles.input}
                />
              )}
            />
          </View>

          <View style={styles.formGroup}>
            <CustomText text="Assign this person as:" size="s" style={styles.label} />
            <Controller
              control={control}
              name="role"
              render={({ field: { onChange, value } }) => (
                <View style={styles.radioGroup}>
                  {roleOptions.map((option) => (
                    <Pressable
                      key={option.value}
                      style={[styles.radioOption, value === option.value && styles.radioOptionSelected]}
                      onPress={() => onChange(option.value)}>
                      <View style={styles.radioButtonOuter}>
                        <View
                          style={[styles.radioButtonInner, value === option.value && styles.radioButtonInnerSelected]}
                        />
                      </View>
                      <View style={styles.radioContent}>
                        <CustomText text={option.label} size="s" style={styles.radioLabel} />
                        <CustomText text={option.description} size="xs" style={styles.radioDescription} />
                      </View>
                    </Pressable>
                  ))}
                </View>
              )}
            />
          </View>

          <View style={styles.buttonContainer}>
            <CustomButton
              text="Cancel"
              onPress={onClose}
              styleType="secondary"
              size="m"
              style={styles.cancelButton}
              textStyle={styles.cancelButtonText}
              leftIcon={null}
              rightIcon={null}
              disabled={false}
              textProps={{}}
            />
            <CustomButton
              text="Send Invite"
              onPress={handleSubmit(handleFormSubmit)}
              styleType="primary"
              size="m"
              style={styles.sendButton}
              textStyle={styles.sendButtonText}
              leftIcon={null}
              rightIcon={null}
              disabled={false}
              textProps={{}}
            />
          </View>
        </View>
      </CustomModal>
      <NewCustomAxioActionModal
        visible={modalState.state === InviteFacultyModalState.INVITE_CONFIRMATION}
        onClose={() => setModalState({ state: InviteFacultyModalState.CLOSED })}
        modalTexts={modals.inviteConfirmation}
        contextualText={emailValue}
      />
      <NewCustomAxioActionModal
        visible={modalState.state === InviteFacultyModalState.INVITE_ERROR}
        onClose={() => setModalState({ state: InviteFacultyModalState.CLOSED })}
        modalTexts={modals.inviteError}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  formGroup: {
    marginBottom: 24,
  },
  label: {
    color: "#111827",
    marginBottom: 8,
    fontWeight: "500",
  },
  input: {
    width: "100%",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 12,
    marginTop: 8,
  },
  cancelButton: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  sendButton: {
    backgroundColor: "#4F46E5",
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  cancelButtonText: {
    color: "#4B5563",
  },
  sendButtonText: {
    color: "#FFFFFF",
  },
  radioGroup: {
    gap: 12,
  },
  radioOption: {
    flexDirection: "row",
    alignItems: "flex-start",
    padding: 12,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#D1D5DB",
    backgroundColor: "#F9FAFB",
  },
  radioOptionSelected: {
    borderColor: "#4F46E5",
    backgroundColor: "#EEF2FF",
  },
  radioButtonOuter: {
    width: 20,
    height: 20,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: "#6B7280",
    marginRight: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  radioButtonInner: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "transparent",
  },
  radioButtonInnerSelected: {
    backgroundColor: "#4F46E5",
  },
  radioContent: {
    flex: 1,
  },
  radioLabel: {
    fontWeight: "500",
    color: "#111827",
    marginBottom: 4,
  },
  radioDescription: {
    color: "#6B7280",
  },
});

export default InviteFacultyModal;
