import React, { useState, useEffect } from "react";
import { VictoryChart, VictoryScatter, VictoryTheme, VictoryAxis } from "victory-native";
import { View, Text } from "react-native";
import Divider from "../../../components/common/layout/Divider/Divider";

const BubbleChartContainer = ({ containerWidth, data, onDrillDown, fixedDomain }) => {
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const domain = fixedDomain || { x: [-10, 10], y: [-10, 10] };

  return (
    <View style={{ flexDirection: "row", padding: 20 }}>
      {/* Graph Section */}
      <View style={{ flex: 2, position: "relative" }}>
        <View
          style={{
            position: "absolute",
            top: 50,
            left: 50,
            right: 50,
            bottom: 50,
            pointerEvents: "none",
          }}>
          <View
            style={{
              position: "absolute",
              backgroundColor: "#FFF1F3",
              top: "50%",
              left: 0,
              right: "50%",
              bottom: 0,
            }}
          />
          <View
            style={{
              position: "absolute",
              backgroundColor: "#F3FEE7",
              top: 0,
              left: "50%",
              right: 0,
              bottom: "50%",
            }}
          />
          <View
            style={{
              position: "absolute",
              backgroundColor: "#FFFAEB",
              top: "50%",
              left: "50%",
              right: 0,
              bottom: 0,
            }}
          />
          <View
            style={{
              position: "absolute",
              backgroundColor: "#FFFAEB",
              top: 0,
              left: 0,
              right: "50%",
              bottom: "50%",
            }}
          />
        </View>

        <VictoryChart
          height={550}
          width={containerWidth}
          domain={domain}
          theme={VictoryTheme.clean}
          padding={{ top: 50, bottom: 50, left: 50, right: 50 }}>
          <VictoryAxis
            style={{
              axis: { stroke: "#98A2B3", strokeWidth: 1 },
              tickLabels: { fontSize: 10 },
            }}
          />
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: "#98A2B3", strokeWidth: 1 },
              tickLabels: { fontSize: 10 },
            }}
          />

          <VictoryScatter
            data={filteredData.filter((d) => !isNaN(d.x) && !isNaN(d.y))}
            size={({ datum }) => datum.size}
            style={{
              data: {
                fill: ({ datum }) => datum.color,
                opacity: 0.85,
                stroke: "#fff",
                strokeWidth: 2,
              },
            }}
            labels={({ datum }) => datum.label}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: (event, props) => {
                    const clickedData = props.datum;
                    if (onDrillDown) {
                      onDrillDown(clickedData);
                    }
                    return [];
                  },
                },
              },
            ]}
          />
        </VictoryChart>

        {/* Quadrant labels */}
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: "none",
          }}>
          <Text
            style={{
              position: "absolute",
              bottom: "50%",
              left: 10,
              fontSize: 14,
              color: "#555",
            }}>
            Confusing
          </Text>
          <Text
            style={{
              position: "absolute",
              bottom: "50%",
              right: 10,
              fontSize: 14,
              color: "#555",
            }}>
            Accessible
          </Text>
          <Text
            style={{
              position: "absolute",
              top: 10,
              left: "50%",
              transform: [{ translateX: -40 }],
              fontSize: 14,
              color: "#555",
            }}>
            Interesting
          </Text>
          <Text
            style={{
              position: "absolute",
              bottom: 10,
              left: "50%",
              transform: [{ translateX: -40 }],
              fontSize: 14,
              color: "#555",
            }}>
            Boring
          </Text>
        </View>
      </View>

      {/* Right side (legend) */}
      <View style={{ flex: 1, paddingLeft: 20 }}>
        {/* Combined Legend */}
        <View style={{ flexDirection: "column" }}>
          {/* Bubble Color Legend */}
          <View>
            <Text style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>Bubble Color:</Text>
            <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}>
              <View style={{ width: 15, height: 15, backgroundColor: "#85E139", marginRight: 5 }} />
              <Text>Positive Sentiment</Text>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}>
              <View style={{ width: 15, height: 15, backgroundColor: "#0084FF", marginRight: 5 }} />
              <Text>Mixed Sentiment</Text>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}>
              <View style={{ width: 15, height: 15, backgroundColor: "#F63D68", marginRight: 5 }} />
              <Text>Negative Sentiment</Text>
            </View>
          </View>
          <Divider />
          {/* Bubble Size Legend */}
          <View>
            <Text style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>Bubble Size:</Text>
            <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}>
              <View
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: "#aaa",
                  marginRight: 5,
                }}
              />
              <Text>Small: Low Enrollment</Text>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}>
              <View
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  backgroundColor: "#aaa",
                  marginRight: 5,
                }}
              />
              <Text>Large: High Enrollment</Text>
            </View>
          </View>
        </View>

        {/* Example filter UI */}
        {/* <Text style={{ marginTop: 10, fontWeight: "bold" }}>Filter Data:</Text>
        <Text
          style={{ color: "blue", textDecorationLine: "underline", marginBottom: 5 }}
          onPress={() => setFilteredData(data.filter((d) => d.color === "#4ECDC4"))}>
          Show Positive
        </Text>
        <Text style={{ color: "blue", textDecorationLine: "underline" }} onPress={() => setFilteredData(data)}>
          Show All
        </Text> */}
      </View>
    </View>
  );
};

export default BubbleChartContainer;
