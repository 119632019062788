import { StyleSheet, View } from "react-native";
import CustomText from "../general/CustomText/CustomText";

const Pill = ({
  text,
  leftIcon,
  backgroundColor,
  textColor,
}: {
  text?: string;
  leftIcon?: JSX.Element;
  backgroundColor?: string;
  textColor?: string;
}) => {
  return (
    <View style={[styles.pill, { backgroundColor: backgroundColor }]}>
      {leftIcon && leftIcon}
      {text && <CustomText text={text} size="s" style={{ color: textColor }} useTranslationText={false} />}
    </View>
  );
};

export default Pill;

const styles = StyleSheet.create({
  pill: {
    borderRadius: 999,
    paddingHorizontal: 8,
    paddingVertical: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
});
