import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";

const NotificationBar = ({ message, backgroundColor }) => {
  if (!message || message.trim() === "") {
    return null;
  }

  return (
    <View style={[styles.container, { backgroundColor }]}>
      <CustomText useTranslationText={false} text={message} style={styles.message} styleType="light" size="m" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  message: {
    textAlign: "center",
    flex: 1,
  },
});

export default NotificationBar;
