import React from "react";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import DeleteResource from "../DeleteResource/DeleteResource";

const DeleteResourceModalContainer = ({ visible, onClose, onDeleteResource, type }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title={`Delete ${type}`}
      style={{ width: isMedium ? "50%" : "90%" }}>
      <DeleteResource deleteButtonClick={() => onDeleteResource(type)} backButtonClick={onClose} type={type} />
    </CustomModal>
  );
};

export default DeleteResourceModalContainer;
