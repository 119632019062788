import { useMemo } from "react";
import { FormattedAiTutor, useGetAllAiTutorsByCourseQuery } from "../graphql/generated/graphql";

export interface CourseProgress {
  overall: number;
  timeLeft: number;
  coreCourses: number;
  totalCoreCourses: number;
  generalCourses: number;
  totalGeneralCourses: number;
  electives: number;
  totalElectives: number;
}

export function useProgressStats(courses: any[], courseIds: string[]) {
  const { data: aiTutorData, loading: aiTutorsLoading } = useGetAllAiTutorsByCourseQuery({
    variables: {
      courseIds,
    },
    skip: !courses.length,
  });

  const progressStats = useMemo(() => {
    if (!courses || !aiTutorData?.getAllAITutorsByCourse.data) {
      return {
        overall: 0,
        timeLeft: 0,
        coreCourses: 0,
        totalCoreCourses: 0,
        generalCourses: 0,
        totalGeneralCourses: 0,
        electives: 0,
        totalElectives: 0,
      };
    }

    const aiTutors = aiTutorData.getAllAITutorsByCourse.data;

    // Calculate total objectives and completed objectives
    let totalObjectives = 0;
    let completedObjectives = 0;
    let timeLeftInMinutes = 0;

    aiTutors.forEach((aiTutor: FormattedAiTutor) => {
      // If no objectives or empty array, count as 4 not started objectives
      if (!aiTutor.objectives || aiTutor.objectives.length === 0) {
        totalObjectives += 4; // Add to total count
        timeLeftInMinutes += 40; // 4 objectives * 10 minutes
        // completedObjectives stays the same since none are completed
      } else {
        aiTutor.objectives.forEach((objective) => {
          totalObjectives++;

          if (objective.status === "completed") {
            completedObjectives++;
          } else {
            timeLeftInMinutes += 10;
          }
        });
      }
    });

    // Convert minutes to hours and round to nearest hour
    const timeLeftInHours = Math.ceil(timeLeftInMinutes / 60);

    // Calculate overall progress percentage including default objectives
    const overallProgress = totalObjectives > 0 ? Math.round((completedObjectives / totalObjectives) * 100) : 0;

    // Count courses by type and completion
    const courseProgress = courses.reduce(
      (acc, course) => {
        const courseType = course.type?.toLowerCase();
        const courseAssignments = aiTutors.filter((a: FormattedAiTutor) => a.courseId === course.id);

        // Calculate if course is completed (all objectives completed)
        const isCompleted = courseAssignments.every((assignment) => {
          if (!assignment.objectives || assignment.objectives.length === 0) {
            return false; // Course with no objectives is not completed
          }
          return assignment.objectives.every((obj) => obj.status === "completed");
        });

        // Update counters based on course type
        if (courseType === "core") {
          acc.totalCoreCourses++;
          if (isCompleted) acc.coreCourses++;
        } else if (courseType === "general") {
          acc.totalGeneralCourses++;
          if (isCompleted) acc.generalCourses++;
        } else if (courseType === "elective") {
          acc.totalElectives++;
          if (isCompleted) acc.electives++;
        }
        // TODO - Change this later for now all courses are core
        acc.totalCoreCourses += 1;

        return acc;
      },
      {
        coreCourses: 0,
        totalCoreCourses: 0,
        generalCourses: 0,
        totalGeneralCourses: 0,
        electives: 0,
        totalElectives: 0,
      }
    );

    return {
      overall: overallProgress,
      timeLeft: timeLeftInHours,
      ...courseProgress,
    };
  }, [courses, aiTutorData]);

  return { progressStats, loading: aiTutorsLoading };
}
