import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.316 5.494L8.614 6.79l2.624-2.624a3.25 3.25 0 114.596 4.597l-2.624 2.623 1.296 1.297 2.624-2.624A5.083 5.083 0 109.94 2.87L7.317 5.494zM4.165 11.237L6.374 9.03 5.077 7.733 2.87 9.94a5.083 5.083 0 007.189 7.189l2.208-2.208-1.296-1.297-2.208 2.209a3.25 3.25 0 01-4.597-4.597z"
        fill="#98A2B3"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.406 12.298a.917.917 0 001.297 1.297l5.892-5.893A.917.917 0 0012.3 6.406l-5.893 5.892z"
        fill="#98A2B3"
      />
    </Svg>
  );
}

export default SvgComponent;
