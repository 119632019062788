import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";
import { v4 as uuidv4 } from "uuid";

function NooroLogo(props) {
  const clipPathId = uuidv4();

  return (
    <Svg width={102} height={26} viewBox="0 0 102 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={`url(#${clipPathId})`}>
        <Path
          d="M34.558 6.666c-2.637 0-4.825.959-6.565 2.876-1.574 1.751-2.36 3.815-2.36 6.19 0 2.387.83 4.474 2.492 6.261 1.663 1.787 3.807 2.68 6.433 2.68 2.616 0 4.757-.893 6.425-2.68 1.667-1.787 2.5-3.874 2.5-6.26 0-2.387-.786-4.456-2.36-6.206-1.74-1.907-3.928-2.86-6.565-2.86zm5.784 12.576c-.594 1.079-1.4 1.907-2.416 2.486-1.016.578-2.138.867-3.368.867a6.673 6.673 0 01-3.345-.867c-1.021-.579-1.832-1.407-2.431-2.486a7.048 7.048 0 01-.899-3.478c0-1.917.657-3.553 1.97-4.908 1.313-1.355 2.881-2.032 4.705-2.032 1.824 0 3.392.677 4.705 2.032 1.313 1.355 1.97 2.99 1.97 4.908 0 1.24-.297 2.4-.891 3.478zM92.41 9.527c-1.74-1.907-3.928-2.86-6.564-2.86-2.637 0-4.825.958-6.566 2.875-1.573 1.751-2.36 3.815-2.36 6.19 0 2.387.83 4.474 2.493 6.261 1.662 1.787 3.806 2.68 6.433 2.68 2.615 0 4.757-.893 6.424-2.68 1.668-1.787 2.501-3.874 2.501-6.26 0-2.387-.787-4.456-2.36-6.206zm-.78 9.715c-.595 1.079-1.4 1.907-2.416 2.486-1.016.578-2.139.867-3.368.867a6.674 6.674 0 01-3.346-.867c-1.02-.579-1.831-1.407-2.43-2.486a7.048 7.048 0 01-.9-3.478c0-1.917.657-3.553 1.97-4.908 1.314-1.355 2.882-2.032 4.706-2.032 1.823 0 3.392.677 4.705 2.032 1.313 1.355 1.97 2.99 1.97 4.908 0 1.24-.298 2.4-.892 3.478zM55.645 6.666c-2.636 0-4.825.959-6.565 2.876-1.574 1.751-2.36 3.815-2.36 6.19 0 2.387.83 4.474 2.493 6.261 1.662 1.787 3.806 2.68 6.432 2.68 2.616 0 4.757-.893 6.425-2.68 1.667-1.787 2.5-3.874 2.5-6.26 0-2.387-.786-4.456-2.36-6.206-1.74-1.907-3.928-2.86-6.565-2.86zm5.784 12.576c-.594 1.079-1.4 1.907-2.415 2.486-1.016.578-2.14.867-3.369.867a6.673 6.673 0 01-3.345-.867c-1.021-.579-1.832-1.407-2.43-2.486a7.047 7.047 0 01-.9-3.478c0-1.917.657-3.553 1.97-4.908 1.313-1.355 2.881-2.032 4.705-2.032 1.824 0 3.392.677 4.705 2.032 1.313 1.355 1.97 2.99 1.97 4.908 0 1.24-.297 2.4-.891 3.478z"
          fill="#212121"
        />
        <Path
          d="M54.777.794c-2.223 2.11-5.84 3.482-9.923 3.482-4.084 0-7.7-1.373-9.923-3.482H32.27c2.493 2.998 7.194 5.019 12.584 5.019S54.945 3.792 57.438.794h-2.66z"
          fill="#40A798"
        />
        <Path
          d="M92.52 3.303H79.17V5.03h13.35V3.303zM67.79 24.627V14.455c0-7.986 8.661-6.82 8.661-6.82-.353 1.23-1.141 1.845-1.141 1.845s-5.14-.963-5.268 4.964v10.183h-2.251zM15.429 7.638c-.43-.045-.827-.061-1.167-.062-.707-.002-1.167.06-1.167.06l.001.002c-2.565.266-6.327 1.553-6.327 6.816v10.173h2.252V14.445c0-5.273 5.241-4.97 5.241-4.97s5.242-.303 5.242 4.97v10.182h2.252V14.455c0-5.264-3.762-6.551-6.327-6.817z"
          fill="#212121"
        />
      </G>
      <Defs>
        <ClipPath id={clipPathId}>
          <Path fill="#fff" transform="translate(6.386 .5)" d="M0 0H89V25H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default NooroLogo;
