import { DataTable } from "react-native-paper";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import { FlatList, View, StyleSheet } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Menu } from "react-native-paper";
import { useState } from "react";
import { UsersTabModalState } from "./UsersTabContainer";
import { NewCustomActionButton } from "../../../components/common/general/CustomButton/NewCustomActionButton";
import Pill from "../../../components/common/Pill/Pill";

export interface UsersTableProps {
  faculties: {
    id: string;
    name: string;
    email: string;
    lastActive: string;
    hasRoleRoles: { name: string }[];
    teachingCourses: { name: string }[];
    pendingFacultyInvite: boolean;
    hasSessionDeviceSessions: { id: string }[];
  }[];
  onActionModalOpen: (facultyId: string, facultyName: string, modalType: UsersTabModalState) => void;
}

type MenuState = {
  visible: boolean;
  x: number;
  y: number;
  facultyId: string;
  facultyName: string;
  courseCount: number;
  facultyRole: string | null;
};

const UsersTable = ({ faculties, onActionModalOpen }: UsersTableProps) => {
  const [menuState, setMenuState] = useState<MenuState>({
    visible: false,
    x: 0,
    y: 0,
    facultyId: null,
    facultyName: null,
    courseCount: 0,
    facultyRole: null,
  });

  const handleOpenMenu = (
    event: any,
    facultyId: string,
    facultyName: string,
    courseCount: number,
    facultyRole: string
  ) => {
    const { nativeEvent } = event;
    setMenuState({
      visible: true,
      x: nativeEvent.pageX - 100,
      y: nativeEvent.pageY,
      facultyId,
      facultyName,
      courseCount,
      facultyRole,
    });
  };

  const handleCloseMenu = () => {
    setMenuState((prev) => ({ ...prev, visible: false, facultyId: null, facultyName: null }));
  };

  const handleActionModalOpen = (modalType: UsersTabModalState, userId?: string, facultyName?: string) => {
    console.log("menuState", menuState, userId, facultyName);
    onActionModalOpen(menuState.facultyId ?? userId, menuState.facultyName ?? facultyName, modalType);
    handleCloseMenu();
  };

  return (
    <>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title style={styles.nameColumn}>
            <CustomText text="Name" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          <DataTable.Title style={styles.teachingColumn}>
            <CustomText text="Teaching" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          {/* <DataTable.Title style={styles.lastActiveColumn}>
            <CustomText text="Last Active" size="s" weight="medium" style={{}} />
          </DataTable.Title> */}
          <DataTable.Title style={styles.roleColumn}>
            <CustomText text="Role" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          <DataTable.Title style={styles.moreColumn}>
            <CustomText text="Actions" size="s" weight="medium" style={{}} />
          </DataTable.Title>
        </DataTable.Header>
        <FlatList
          data={faculties}
          keyExtractor={(item) => item.id}
          renderItem={({ item: faculty }) => (
            <DataTable.Row style={styles.tableRow}>
              <DataTable.Cell style={styles.nameColumn}>
                <View style={styles.nameCell}>
                  {faculty.pendingFacultyInvite ? (
                    <View style={styles.avatar}>
                      <CustomText
                        text={faculty.name[0]}
                        size="s"
                        weight="medium"
                        useTranslationText={false}
                        style={styles.avatarText}
                      />
                    </View>
                  ) : (
                    <LinearGradient
                      colors={["#3E68FE", "#913DAF"]}
                      style={styles.avatar}
                      start={{ x: 0, y: 0.5 }}
                      end={{ x: 1, y: 0.5 }}>
                      <CustomText
                        text={faculty.name[0]}
                        size="s"
                        weight="medium"
                        useTranslationText={false}
                        style={[styles.avatarText, styles.activeAvatarText]}
                      />
                    </LinearGradient>
                  )}
                  <View style={styles.nameInfo}>
                    <CustomText
                      text={faculty.name}
                      size="s"
                      weight="medium"
                      useTranslationText={false}
                      style={styles.nameText}
                    />
                    <CustomText text={faculty.email} size="xs" style={styles.emailText} useTranslationText={false} />
                  </View>
                </View>
              </DataTable.Cell>
              <DataTable.Cell style={styles.teachingColumn}>
                {faculty.pendingFacultyInvite ? (
                  <CustomText text="Pending Confirmation" size="s" style={styles.pendingText} />
                ) : faculty.teachingCourses.length === 0 ? (
                  <NewCustomActionButton
                    text="Assign Course"
                    onPress={() => handleActionModalOpen(UsersTabModalState.ASSIGN_COURSE, faculty.id, faculty.name)}
                    leftIcon={null}
                  />
                ) : (
                  <View style={styles.teachingColumn}>
                    {faculty.teachingCourses.map((course, index) => (
                      <Pill key={index} text={course.name} leftIcon={null} backgroundColor="#E5E7EB" textColor="#666" />
                    ))}
                  </View>
                )}
              </DataTable.Cell>
              {/* <DataTable.Cell style={styles.lastActiveColumn}>
                <CustomText
                  text={member.hasSessionDeviceSessions?.length > 0 ? "Active" : "Inactive"}
                  size="s"
                  style={styles.lastActiveText}
                  useTranslationText={false}
                />
              </DataTable.Cell> */}
              <DataTable.Cell style={styles.roleColumn}>
                <CustomText
                  text={faculty.hasRoleRoles[0].name}
                  size="s"
                  style={styles.roleText}
                  useTranslationText={false}
                />
              </DataTable.Cell>
              <DataTable.Cell style={styles.moreColumn}>
                <CustomButton
                  text="⋮"
                  size="l"
                  styleType="primaryTransparent"
                  style={styles.moreButtonText}
                  useTranslationText={false}
                  onPress={(e) =>
                    handleOpenMenu(
                      e,
                      faculty.id,
                      faculty.name,
                      faculty.teachingCourses.length,
                      faculty.hasRoleRoles[0].name // TODO: ASSUMES ONLY 1 ROLE
                    )
                  }
                  leftIcon={null}
                  rightIcon={null}
                  textStyle={{}}
                  disabled={false}
                  textProps={{}}
                />
              </DataTable.Cell>
            </DataTable.Row>
          )}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.listContent}
        />
      </DataTable>

      <Menu
        visible={menuState.visible}
        onDismiss={handleCloseMenu}
        anchor={{ x: menuState.x, y: menuState.y }}
        contentStyle={styles.menuItem}>
        <Menu.Item
          disabled={menuState.facultyRole === "School Admin"}
          onPress={() => handleActionModalOpen(UsersTabModalState.REVOKE)}
          title="Revoke Faculty"
          titleStyle={{ color: menuState.facultyRole === "School Admin" ? "#6B7280" : "#DC2626" }}
        />
        <Menu.Item
          disabled={menuState.courseCount === 0}
          onPress={() => handleActionModalOpen(UsersTabModalState.REMOVE_COURSE)}
          title="Remove Course"
          titleStyle={{ color: menuState.courseCount === 0 ? "#6B7280" : "#3E68FE" }}
        />
      </Menu>
    </>
  );
};

export default UsersTable;

const styles = StyleSheet.create({
  listContent: {
    flexGrow: 1,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    width: "100%",
    paddingVertical: 16,
  },
  nameCell: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: "#e0e0e0",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
  },
  avatarText: {
    color: "#666",
  },
  nameText: {
    color: "#000",
  },
  nameInfo: {
    flex: 1,
  },
  emailText: {
    color: "#666",
    marginTop: 4,
  },
  pendingText: {
    color: "#666",
    fontStyle: "italic",
  },
  roleText: {
    flex: 1,
    color: "#666",
  },
  moreButtonText: {
    color: "#666",
  },
  activeAvatarText: {
    color: "#FFFFFF",
  },
  nameColumn: {
    flex: 5,
  },
  teachingColumn: {
    flex: 5,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 4,
    width: "100%",
    alignItems: "center",
  },
  roleColumn: {
    flex: 2,
  },
  moreColumn: {
    flex: 1,
    flexShrink: 1,
    justifyContent: "flex-end",
  },
  menuItem: {
    minWidth: 150,
    backgroundColor: "#fff",
  },
});
