import React, { useMemo, ReactNode } from "react";
import { View } from "react-native";
import CustomText from "../general/CustomText/CustomText";
import { CheckedCircle, CheckedCircle2, CrossCircle } from "../../svgs/common";

export type RuleNames = "length" | "number" | "capital" | "lowercase";

enum Error {
  Empty = "Empty",
  TooShort = "TooShort",
  StartsWithSpace = "StartsWithSpace",
  EndsWithSpace = "EndsWithSpace",
  NoNumericCharacter = "NoNumericCharacter",
  NoCapitalCharacter = "NoCapitalCharacter",
  NoLowercaseCharacter = "NoLowercaseCharacter",
}

export const ErrorMessageErrorMap = {
  [Error.Empty]: "You need to set a password",
  [Error.TooShort]: "The password is too short",
  [Error.StartsWithSpace]: "The password cannot start with a space",
  [Error.EndsWithSpace]: "The password cannot end with a space",
  [Error.NoNumericCharacter]: "The password does not contain a number",
  [Error.NoCapitalCharacter]: "The password does not contain an uppercase letter",
  [Error.NoLowercaseCharacter]: "The password does not contain a lowercase letter",
};

export const validatePassword = (value: string) => {
  const validationResult = getErrors(value ?? "");

  if (validationResult.length === 0) {
    return true;
  }

  return ErrorMessageErrorMap[validationResult[0]];
};

export const getErrors = (value: string): Error[] => {
  const errors: Error[] = [];

  if (!value) {
    errors.push(Error.Empty);
  }

  if (value.length < MIN_LENGTH) {
    errors.push(Error.TooShort);
  }

  if (value.startsWith(" ")) {
    errors.push(Error.StartsWithSpace);
  }

  if (value.endsWith(" ")) {
    errors.push(Error.EndsWithSpace);
  }

  if (!/\d/.test(value)) {
    errors.push(Error.NoNumericCharacter);
  }

  if (!/[A-Z]/.test(value)) {
    errors.push(Error.NoCapitalCharacter);
  }

  if (!/[a-z]/.test(value)) {
    errors.push(Error.NoLowercaseCharacter);
  }

  return errors;
};

export interface PasswordChecklistProps {
  value: string;
  rules: Array<RuleNames>;
}

const MIN_LENGTH = 8;

export const PasswordChecklist: React.FC<PasswordChecklistProps> = ({ rules, value, ...remainingProps }) => {
  const ruleDefinitions: {
    [key in RuleNames]: { valid: boolean; message: string };
  } = useMemo(() => {
    const errors = getErrors(value ?? "");
    const result = {
      length: {
        valid: false,
        message: `At least ${MIN_LENGTH} characters`,
      },
      number: {
        valid: false,
        message: "Number",
      },
      capital: {
        valid: false,
        message: "Uppercase letter",
      },
      lowercase: {
        valid: false,
        message: "Lowercase letter",
      },
    };

    if (errors.indexOf(Error.TooShort) === -1) {
      result.length.valid = true;
    }

    if (errors.indexOf(Error.NoNumericCharacter) === -1) {
      result.number.valid = true;
    }

    if (errors.indexOf(Error.NoCapitalCharacter) === -1) {
      result.capital.valid = true;
    }

    if (errors.indexOf(Error.NoLowercaseCharacter) === -1) {
      result.lowercase.valid = true;
    }

    return result;
  }, [value]);

  const enabledRules = rules.filter((rule) => Boolean(ruleDefinitions[rule]));

  return (
    <View>
      {enabledRules.map((rule) => {
        const { message, valid } = ruleDefinitions[rule];
        return (
          <Rule key={rule} valid={valid} {...remainingProps}>
            {message}
          </Rule>
        );
      })}
    </View>
  );
};

interface RuleProps {
  valid: boolean;
  children: ReactNode;
}

const Rule: React.FC<RuleProps> = ({ valid, children }) => (
  <View style={{ flexDirection: "row", paddingVertical: 2 }}>
    {valid && (
      <View style={{ marginRight: 10 }}>
        <CheckedCircle2 fill="#66C61C" height="24" width="24" />
      </View>
    )}
    {!valid && (
      <View style={{ marginRight: 10 }}>
        <CrossCircle />
      </View>
    )}
    <CustomText style={{ color: "#667085" }} text={children} size="s" />
  </View>
);
