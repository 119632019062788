import { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MotiView } from "moti";
import { getCategoriesWithAssignments, getModulesWithResources } from "../../../services";
import ItemCardList from "../../../components/lms/ItemCardList/ItemCardList";
import { useNavigation } from "@react-navigation/native";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Loader from "../../../components/common/status/Loader/Loader";
import Sentry from "../../../utils/sentry";
import { useAssignmentsQuery } from "../../../graphql/generated/graphql";

function SequenceCardListContainer({ integrationId, courseId, isGoogle }) {
  const [categoriesWithAssignments, setCategoriesWithAssignments] = useState([]);
  const [modulesWithResources, setModulesWithResources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigation();

  const { data: assignmentsData } = useAssignmentsQuery();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const token = await AsyncStorage.getItem("token");
      try {
        if (isGoogle) {
          const data = await getCategoriesWithAssignments(token, integrationId, courseId);
          setCategoriesWithAssignments(data);
        } else {
          const mod = await getModulesWithResources(token, integrationId, courseId);
          console.log("mod", mod);

          const formattedMod = await Promise.all(
            mod.map(async (module) => {
              const resources = await Promise.all(
                module.resources.map(async (resource) => {
                  console.log("resource", resource);
                  const assignment = assignmentsData?.getAssignments.find(
                    (assignment) => assignment.id === resource.target_id
                  );
                  const isComplete =
                    assignment?.objectives && assignment.objectives.length > 0
                      ? assignment.objectives.every((objective) => objective.status === "completed")
                      : false;
                  return {
                    ...resource,
                    details: {
                      ...resource.details,
                      isComplete,
                    },
                  };
                })
              );

              return {
                ...module,
                resources,
              };
            })
          );
          setModulesWithResources(formattedMod);
        }
      } catch (error) {
        console.error("error formatting modules", error);
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [integrationId, courseId, isGoogle, assignmentsData]);

  const handlePress = (id) => {
    const category = categoriesWithAssignments.find((category) =>
      category.assignments.some((assignment) => assignment.id === id)
    );
    const assignment = category.assignments.find((assignment) => assignment.id === id);

    navigation.navigate("Resource", {
      resource_type: "assignment",
      resource_id: assignment.id,
      course_id: courseId,
      integration_id: integrationId,
    });
  };

  const handleResourcePress = (id) => {
    const resource = modulesWithResources.flatMap((module) => module.resources).find((res) => res.id === id);

    if (resource && resource.details) {
      navigation.navigate("Resource", {
        resource_type: resource.type,
        resource_id: resource.details.id,
        course_id: courseId,
        integration_id: integrationId,
      });
    } else {
      Sentry.captureException(`Resource with id ${id} not found or missing details.`);
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <Loader />
          <CustomSpacing type="vertical" size="s" />
          <CustomText useTranslationText={false} text="Loading..." size="m" />
        </View>
      );
    }

    if (isGoogle) {
      return (
        <ScrollView style={{ flex: 1 }}>
          {categoriesWithAssignments.map((category) => (
            <View key={category.id}>
              <CustomSpacing type="vertical" size="l" />
              <CustomText
                text={category.title}
                size="xs"
                textType="display"
                useTranslationText={false}
                role="header"
                aria-level="2"
              />
              <CustomSpacing type="vertical" size="l" />
              <ItemCardList items={category.assignments} onPress={handlePress} />
            </View>
          ))}
        </ScrollView>
      );
    } else {
      return (
        <ScrollView style={{ flex: 1 }}>
          {modulesWithResources.map((module) => (
            <View key={module.id}>
              <CustomSpacing type="vertical" size="l" />
              <CustomText
                text={module.title}
                size="l"
                style={{ fontFamily: "AbrilFatface" }}
                useTranslationText={false}
              />
              <CustomSpacing type="vertical" size="l" />
              <ItemCardList items={module.resources} onPress={handleResourcePress} />
            </View>
          ))}
        </ScrollView>
      );
    }
  };

  return (
    <MotiView
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      delay={1500}
      transition={{ type: "timing", duration: 600 }}
      style={{ flex: 1 }}
      aria-live="polite">
      {renderContent()}
    </MotiView>
  );
}

export default SequenceCardListContainer;
