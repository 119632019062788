import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function EditTaskGrey(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#clip0_109_265)">
        <Path
          d="M18.163 4.337l-2.5-2.5a.937.937 0 00-1.328 0l-7.5 7.5a.938.938 0 00-.272.663v2.5a.937.937 0 00.937.938H10a.936.936 0 00.663-.275l7.5-7.5a.939.939 0 000-1.326zM15 3.828L16.172 5l-.86.86-1.171-1.172.859-.86zm-5.39 7.735H8.437V10.39l4.374-4.375 1.172 1.172-4.375 4.375zm8.203-1.206v5.893a1.562 1.562 0 01-1.563 1.563H3.75a1.563 1.563 0 01-1.563-1.563V3.75A1.562 1.562 0 013.75 2.188h5.893a.938.938 0 110 1.875h-5.58v11.875h11.875v-5.58a.938.938 0 011.874 0z"
          fill="#98A2B3"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_109_265">
          <Path fill="#fff" d="M0 0H20V20H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default EditTaskGrey;
