import { useEffect, useState } from "react";
import { useGetFacultyByNameOrEmailQuery, useSchoolsQuery } from "../../../graphql/generated/graphql";
import SearchListModal from "../../../components/common/SearchListModal/SearchListModal";
import { CoursesTabModalState } from "./CoursesTabContainer";

const AssignFacultyToCourseListContainer = ({
  visible,
  onClose,
  onAction,
}: {
  visible: boolean;
  onClose: () => void;
  onAction: (id: string) => void;
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { data: schoolsData } = useSchoolsQuery();

  const { data: facultyData, refetch: facultyRefetch } = useGetFacultyByNameOrEmailQuery({
    variables: {
      nameOrEmail: searchQuery,
      schoolId: schoolsData?.getSchools[0].id,
      page: 1,
      pageSize: 5,
    },
  });

  useEffect(() => {
    facultyRefetch();
  }, [searchQuery]);

  const list =
    facultyData?.getFacultyByNameOrEmail?.data.faculties.map((faculty) => ({
      id: faculty.id,
      name: faculty.name,
    })) || [];

  return (
    <SearchListModal<CoursesTabModalState>
      visible={visible}
      onClose={onClose}
      onAction={onAction}
      list={list}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      modalType={CoursesTabModalState.ASSIGN_FACULTY}
    />
  );
};

export default AssignFacultyToCourseListContainer;
