import * as React from "react";
import Svg, { Path } from "react-native-svg";

function File(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M20.296 7.455l-5.25-5.25a1.124 1.124 0 00-.796-.33h-9A1.875 1.875 0 003.375 3.75v16.5a1.875 1.875 0 001.875 1.875h13.5a1.875 1.875 0 001.875-1.875v-12c0-.298-.118-.584-.33-.795zM15 5.344L17.156 7.5H15V5.344zM5.625 19.875V4.125h7.125v4.5a1.125 1.125 0 001.125 1.125h4.5v10.125H5.625z"
        fill="#344054"
      />
    </Svg>
  );
}

export default File;
