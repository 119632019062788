import React, { useEffect, useState } from "react";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import EditModule from "../EditModule/EditModule";

const EditModuleNameModalContainer = ({
  visible,
  onClose,
  moduleName,
  onUpdateModuleName,
  hasOrder = false,
  currentOrder,
  moduleId,
}) => {
  const { isMedium } = useResponsiveScreen();
  const [newModuleName, setNewModuleName] = useState(moduleName);
  const [order, setOrder] = useState(currentOrder ?? 0);

  useEffect(() => {
    if (moduleName) {
      setNewModuleName(moduleName);
    } else {
      setNewModuleName("");
    }

    setOrder(currentOrder ?? 0);

    return () => {
      setNewModuleName("");
      setOrder(0);
    };
  }, [visible, moduleName, currentOrder]);

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      style={{ width: isMedium ? "50%" : "90%", padding: 0, maxWidth: 500 }}>
      <EditModule
        onChangeName={setNewModuleName}
        moduleName={newModuleName ?? moduleName}
        saveButtonClick={() => onUpdateModuleName(moduleName, newModuleName, order, moduleId)}
        hasOrder={hasOrder}
        order={order ?? currentOrder}
        setOrder={setOrder}
        cancelButtonClick={onClose}
      />
    </CustomModal>
  );
};

export default EditModuleNameModalContainer;
