import React, { useState } from "react";
import { View, StyleSheet, Text } from "react-native";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import StudentBubbleChartContainer from "../containers/analytics/StudentBubbleChartContainer/StudentBubbleChartContainer";
import BubbleChartContainer from "../containers/analytics/BubbleChartContainer/BubbleChartContainer";
import AccordionAnalyticsContainer from "../containers/analytics/AccordionAnalyticsContainer.js/AccordionAnalyticsContainer";

/** Example data:
 * top-level: students => courses => modules => activities => objectives
 * For the top-level "student," we want a Risk vs. Performance axis.
 */
export const mockStudentData = [
  {
    label: "Student A",
    success_points: 10,
    confusion_points: 3,
    questions_asked: 7,
    active_interaction_count: 5,
    distractions: 2,
    sentiment: "positive",
    enrollment: 1,
    details: {
      courses: [
        {
          label: "Course: Physics 101",
          success_points: 8,
          confusion_points: 2,
          questions_asked: 5,
          active_interaction_count: 4,
          distractions: 1,
          sentiment: "mixed",
          enrollment: 300,
          details: {
            modules: [
              {
                label: "Module 1: Kinematics",
                success_points: 6,
                confusion_points: 4,
                questions_asked: 3,
                active_interaction_count: 2,
                distractions: 1,
                sentiment: "positive",
                enrollment: 150,
                details: {
                  activities: [
                    {
                      label: "Activity 1: Velocity & Acceleration",
                      success_points: 5,
                      confusion_points: 3,
                      questions_asked: 2,
                      active_interaction_count: 2,
                      distractions: 1,
                      sentiment: "positive",
                      enrollment: 80,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Define Velocity",
                            success_points: 4,
                            confusion_points: 2,
                          },
                          {
                            label: "Objective 2: Calculate Acceleration",
                            success_points: 5,
                            confusion_points: 3,
                          },
                        ],
                      },
                    },
                    {
                      label: "Activity 2: Projectile Motion",
                      success_points: 4,
                      confusion_points: 3,
                      questions_asked: 3,
                      active_interaction_count: 1,
                      distractions: 1,
                      sentiment: "mixed",
                      enrollment: 70,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Understand Trajectories",
                            success_points: 4,
                            confusion_points: 2,
                          },
                          {
                            label: "Objective 2: Apply Kinematic Formulas",
                            success_points: 5,
                            confusion_points: 3,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                label: "Module 2: Newton's Laws",
                success_points: 7,
                confusion_points: 3,
                questions_asked: 4,
                active_interaction_count: 3,
                distractions: 1,
                sentiment: "mixed",
                enrollment: 120,
                details: {
                  activities: [
                    {
                      label: "Activity 1: Newton's First Law",
                      success_points: 6,
                      confusion_points: 2,
                      questions_asked: 2,
                      active_interaction_count: 2,
                      distractions: 1,
                      sentiment: "positive",
                      enrollment: 60,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Define Inertia",
                            success_points: 5,
                            confusion_points: 2,
                          },
                          {
                            label: "Objective 2: Illustrate Real-World Examples",
                            success_points: 6,
                            confusion_points: 3,
                          },
                        ],
                      },
                    },
                    {
                      label: "Activity 2: Newton's Second Law",
                      success_points: 5,
                      confusion_points: 3,
                      questions_asked: 2,
                      active_interaction_count: 1,
                      distractions: 1,
                      sentiment: "mixed",
                      enrollment: 50,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: F = ma Calculations",
                            success_points: 5,
                            confusion_points: 2,
                          },
                          {
                            label: "Objective 2: Real-World Force Problems",
                            success_points: 6,
                            confusion_points: 3,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          label: "Course: Algebra 201",
          success_points: 9,
          confusion_points: 3,
          questions_asked: 4,
          active_interaction_count: 3,
          distractions: 2,
          sentiment: "negative",
          enrollment: 200,
          details: {
            modules: [
              {
                label: "Module 1: Equations & Inequalities",
                success_points: 6,
                confusion_points: 2,
                questions_asked: 2,
                active_interaction_count: 1,
                distractions: 1,
                sentiment: "mixed",
                enrollment: 120,
                details: {
                  activities: [
                    {
                      label: "Activity 1: Linear Equations",
                      success_points: 5,
                      confusion_points: 2,
                      questions_asked: 2,
                      active_interaction_count: 2,
                      distractions: 1,
                      sentiment: "mixed",
                      enrollment: 60,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Solve Basic Linear Equations",
                            success_points: 5,
                            confusion_points: 1,
                          },
                          {
                            label: "Objective 2: Graph Lines",
                            success_points: 5,
                            confusion_points: 2,
                          },
                        ],
                      },
                    },
                    {
                      label: "Activity 2: Quadratic Equations",
                      success_points: 4,
                      confusion_points: 3,
                      questions_asked: 2,
                      active_interaction_count: 1,
                      distractions: 1,
                      sentiment: "negative",
                      enrollment: 40,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Solve Quadratic Equations",
                            success_points: 4,
                            confusion_points: 3,
                          },
                          {
                            label: "Objective 2: Vertex Form & Factoring",
                            success_points: 5,
                            confusion_points: 2,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                label: "Module 2: Functions & Graphs",
                success_points: 5,
                confusion_points: 3,
                questions_asked: 3,
                active_interaction_count: 2,
                distractions: 1,
                sentiment: "negative",
                enrollment: 80,
                details: {
                  activities: [
                    {
                      label: "Activity 1: Intro to Functions",
                      success_points: 4,
                      confusion_points: 2,
                      questions_asked: 1,
                      active_interaction_count: 2,
                      distractions: 1,
                      sentiment: "mixed",
                      enrollment: 40,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Domain & Range",
                            success_points: 4,
                            confusion_points: 2,
                          },
                          {
                            label: "Objective 2: Basic Transformations",
                            success_points: 3,
                            confusion_points: 2,
                          },
                        ],
                      },
                    },
                    {
                      label: "Activity 2: Polynomial Functions",
                      success_points: 5,
                      confusion_points: 2,
                      questions_asked: 2,
                      active_interaction_count: 1,
                      distractions: 1,
                      sentiment: "negative",
                      enrollment: 40,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: End Behavior Analysis",
                            success_points: 4,
                            confusion_points: 2,
                          },
                          {
                            label: "Objective 2: Zeros & Factor Theorem",
                            success_points: 5,
                            confusion_points: 3,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    label: "Student B",
    success_points: 5,
    confusion_points: 5,
    questions_asked: 6,
    active_interaction_count: 4,
    distractions: 3,
    sentiment: "mixed",
    enrollment: 1,
    details: {
      courses: [
        {
          label: "Course: Literature 101",
          success_points: 4,
          confusion_points: 4,
          questions_asked: 2,
          active_interaction_count: 2,
          distractions: 2,
          sentiment: "mixed",
          enrollment: 180,
          details: {
            modules: [
              {
                label: "Module 1: Poetry Analysis",
                success_points: 4,
                confusion_points: 3,
                questions_asked: 2,
                active_interaction_count: 1,
                distractions: 1,
                sentiment: "mixed",
                enrollment: 100,
                details: {
                  activities: [
                    {
                      label: "Activity 1: Rhyme Schemes",
                      success_points: 3,
                      confusion_points: 2,
                      questions_asked: 1,
                      active_interaction_count: 1,
                      distractions: 1,
                      sentiment: "mixed",
                      enrollment: 50,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Identify Rhyme Patterns",
                            success_points: 3,
                            confusion_points: 2,
                          },
                          {
                            label: "Objective 2: Write a Simple Poem",
                            success_points: 3,
                            confusion_points: 2,
                          },
                        ],
                      },
                    },
                    {
                      label: "Activity 2: Metaphors & Similes",
                      success_points: 4,
                      confusion_points: 3,
                      questions_asked: 1,
                      active_interaction_count: 1,
                      distractions: 1,
                      sentiment: "mixed",
                      enrollment: 50,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Identify Figures of Speech",
                            success_points: 3,
                            confusion_points: 2,
                          },
                          {
                            label: "Objective 2: Analyze Poetic Devices",
                            success_points: 4,
                            confusion_points: 2,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                label: "Module 2: Novel Studies",
                success_points: 3,
                confusion_points: 3,
                questions_asked: 2,
                active_interaction_count: 2,
                distractions: 1,
                sentiment: "mixed",
                enrollment: 80,
                details: {
                  activities: [
                    {
                      label: "Activity 1: Character Analysis",
                      success_points: 3,
                      confusion_points: 2,
                      questions_asked: 2,
                      active_interaction_count: 2,
                      distractions: 1,
                      sentiment: "mixed",
                      enrollment: 40,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Understand Character Development",
                            success_points: 3,
                            confusion_points: 2,
                          },
                          {
                            label: "Objective 2: Explore Motivations",
                            success_points: 3,
                            confusion_points: 2,
                          },
                        ],
                      },
                    },
                    {
                      label: "Activity 2: Thematic Analysis",
                      success_points: 3,
                      confusion_points: 3,
                      questions_asked: 1,
                      active_interaction_count: 2,
                      distractions: 1,
                      sentiment: "mixed",
                      enrollment: 40,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Identify Central Themes",
                            success_points: 3,
                            confusion_points: 2,
                          },
                          {
                            label: "Objective 2: Relate Themes to Real Life",
                            success_points: 3,
                            confusion_points: 3,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          label: "Course: Chemistry 202",
          success_points: 6,
          confusion_points: 4,
          questions_asked: 4,
          active_interaction_count: 3,
          distractions: 2,
          sentiment: "mixed",
          enrollment: 220,
          details: {
            modules: [
              {
                label: "Module 1: Atomic Structure",
                success_points: 5,
                confusion_points: 3,
                questions_asked: 2,
                active_interaction_count: 2,
                distractions: 1,
                sentiment: "positive",
                enrollment: 120,
                details: {
                  activities: [
                    {
                      label: "Activity 1: Electron Configuration",
                      success_points: 5,
                      confusion_points: 2,
                      questions_asked: 2,
                      active_interaction_count: 2,
                      distractions: 1,
                      sentiment: "positive",
                      enrollment: 60,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Understand Orbitals",
                            success_points: 4,
                            confusion_points: 2,
                          },
                          {
                            label: "Objective 2: Write Electron Configurations",
                            success_points: 5,
                            confusion_points: 2,
                          },
                        ],
                      },
                    },
                    {
                      label: "Activity 2: Isotopes & Ions",
                      success_points: 4,
                      confusion_points: 3,
                      questions_asked: 1,
                      active_interaction_count: 2,
                      distractions: 1,
                      sentiment: "mixed",
                      enrollment: 60,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Differentiate Isotopes",
                            success_points: 3,
                            confusion_points: 2,
                          },
                          {
                            label: "Objective 2: Understand Ion Formation",
                            success_points: 4,
                            confusion_points: 3,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                label: "Module 2: Chemical Reactions",
                success_points: 5,
                confusion_points: 4,
                questions_asked: 2,
                active_interaction_count: 2,
                distractions: 1,
                sentiment: "mixed",
                enrollment: 100,
                details: {
                  activities: [
                    {
                      label: "Activity 1: Balancing Equations",
                      success_points: 4,
                      confusion_points: 3,
                      questions_asked: 1,
                      active_interaction_count: 1,
                      distractions: 1,
                      sentiment: "mixed",
                      enrollment: 50,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Understand Reactants & Products",
                            success_points: 4,
                            confusion_points: 2,
                          },
                          {
                            label: "Objective 2: Balance Simple Reactions",
                            success_points: 4,
                            confusion_points: 3,
                          },
                        ],
                      },
                    },
                    {
                      label: "Activity 2: Reaction Types",
                      success_points: 5,
                      confusion_points: 3,
                      questions_asked: 2,
                      active_interaction_count: 2,
                      distractions: 1,
                      sentiment: "mixed",
                      enrollment: 50,
                      details: {
                        objectives: [
                          {
                            label: "Objective 1: Synthesis & Decomposition",
                            success_points: 5,
                            confusion_points: 3,
                          },
                          {
                            label: "Objective 2: Single & Double Replacement",
                            success_points: 5,
                            confusion_points: 3,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
];

const detailKeyMapping = {
  student: "courses",
  course: "modules",
  module: "activities",
  activity: "objectives",
};
const nextLevelMapping = {
  student: "course",
  course: "module",
  module: "activity",
  activity: "objective",
};

/**
 * We'll define a helper that does the "risk vs. performance"
 * at the student level, then default to the old "success-confusion"
 * formula once we drill deeper.
 */
function mapDataWithConditionalAxes(data, level) {
  if (!data || data.length === 0) {
    console.warn(`No data available for mapping at level: ${level}`);
    return [];
  }

  if (level === "student") {
    // "Risk" on X-axis, "Performance" on Y-axis
    return data.map((item, idx) => {
      const risk = computeRisk(item); // e.g. confusion + distractions (+ penalty if negative sentiment)
      const performance = computePerformance(item); // e.g. success + active_interaction_count + half questions

      // Simple: let's just keep them as raw x,y without scaling
      // Or do some min–max scaling if you prefer. We'll do them raw for demo.
      const enrollment = item.enrollment || 1;
      return {
        x: risk,
        y: performance,
        size: Math.max(enrollment * 10, 10), // or any logic
        color:
          item.sentiment === "positive"
            ? "#4ECDC4"
            : item.sentiment === "mixed"
              ? "#FFDD57"
              : item.sentiment === "negative"
                ? "#FF6B6B"
                : "#0084FF",
        label: item.label || `Student ${idx + 1}`,
        details: item.details || null,
        level: "student",
      };
    });
  } else {
    // FALLBACK to "success minus confusion" vs. "engagement minus distraction"
    const successMinusConfusion = data.map((d) => (d.success_points || 0) - (d.confusion_points || 0));
    const engagementMinusDistraction = data.map(
      (d) => (d.questions_asked || 0) + (d.active_interaction_count || 0) - (d.distractions || 0)
    );

    const minX = Math.min(...successMinusConfusion);
    const maxX = Math.max(...successMinusConfusion);
    const rangeX = maxX - minX || 1;

    const minY = Math.min(...engagementMinusDistraction);
    const maxY = Math.max(...engagementMinusDistraction);
    const rangeY = maxY - minY || 1;

    const maxEnrollment = Math.max(...data.map((d) => d.enrollment || 1), 1);
    const scaleFactor = 10;

    return data.map((item, index) => {
      const sentiment = item.sentiment || "none";
      return {
        x:
          (((item.success_points || 0) - (item.confusion_points || 0) - minX) / rangeX) * 2 * scaleFactor - scaleFactor,
        y:
          (((item.questions_asked || 0) + (item.active_interaction_count || 0) - (item.distractions || 0) - minY) /
            rangeY) *
            2 *
            scaleFactor -
          scaleFactor,
        size: Math.max(((item.enrollment || 1) / maxEnrollment) * 30, 10),
        color:
          sentiment === "positive"
            ? "#4ECDC4"
            : sentiment === "mixed"
              ? "#FFDD57"
              : sentiment === "negative"
                ? "#FF6B6B"
                : "#0084FF",
        label: item.label || `Item ${index + 1}`,
        details: item.details || null,
        level, // "course", "module", "activity", "objective", etc.
      };
    });
  }
}

// Helper to compute risk
function computeRisk(item) {
  const c = item.confusion_points || 0;
  const d = item.distractions || 0;
  let risk = c + d;
  // maybe add a small penalty for negative sentiment
  if (item.sentiment === "negative") risk += 2;
  return risk;
}

// Helper to compute performance
function computePerformance(item) {
  const s = item.success_points || 0;
  const a = item.active_interaction_count || 0;
  const q = item.questions_asked || 0;
  // Weighted formula example
  return s + a + 0.5 * q;
}

const StudentAnalyticsView = () => {
  // MAP the top-level "student" data
  const [currentLevel, setCurrentLevel] = useState("student");
  const [currentData, setCurrentData] = useState(mapDataWithConditionalAxes(mockStudentData, "student"));

  const [breadcrumb, setBreadcrumb] = useState([{ label: "Students", level: "student", data: mockStudentData }]);

  const handleDrillDown = (clickedItem) => {
    if (!clickedItem.details) {
      console.warn("No details available:", clickedItem);
      return;
    }
    const detailKey = detailKeyMapping[clickedItem.level];
    const nextLevel = nextLevelMapping[clickedItem.level];

    if (!detailKey || !nextLevel) {
      console.warn("No further levels to drill down");
      return;
    }
    const nextData = clickedItem.details[detailKey];
    if (!nextData || nextData.length === 0) {
      console.warn(`No data for ${detailKey}`, clickedItem.details);
      return;
    }

    setBreadcrumb((prev) => [...prev, { label: clickedItem.label, level: nextLevel, data: nextData }]);
    setCurrentLevel(nextLevel);
    setCurrentData(mapDataWithConditionalAxes(nextData, nextLevel));
  };

  const handleBreadcrumbClick = (index) => {
    const selected = breadcrumb[index];
    setBreadcrumb(breadcrumb.slice(0, index + 1));
    setCurrentLevel(selected.level);
    setCurrentData(mapDataWithConditionalAxes(selected.data, selected.level));
  };

  // Decide which chart container to use based on `currentLevel`
  // If level === "student", we show a custom chart with "Risk" vs. "Performance" labels
  // else we show the original chart with "Confusing–Accessible / Interesting–Boring"
  let ChartComponent;
  if (currentLevel === "student") {
    ChartComponent = StudentBubbleChartContainer; // custom axis labels
  } else {
    ChartComponent = BubbleChartContainer; // your standard container
  }

  return (
    <NoChatBarLayout>
      <View style={styles.breadcrumb}>
        {breadcrumb.map((crumb, idx) => (
          <Text
            key={idx}
            style={[styles.breadcrumbItem, idx === breadcrumb.length - 1 && styles.activeBreadcrumb]}
            onPress={() => handleBreadcrumbClick(idx)}>
            {crumb.label}
            {idx < breadcrumb.length - 1 && " > "}
          </Text>
        ))}
      </View>

      {/* RENDER the chosen chart */}
      <ChartComponent data={currentData} onDrillDown={handleDrillDown} containerWidth={800} />
      <AccordionAnalyticsContainer data={currentData} />
    </NoChatBarLayout>
  );
};

export default StudentAnalyticsView;

const styles = StyleSheet.create({
  breadcrumb: {
    flexDirection: "row",
    marginBottom: 10,
  },
  breadcrumbItem: {
    color: "blue",
    textDecorationLine: "underline",
    cursor: "pointer",
  },
  activeBreadcrumb: {
    color: "black",
    textDecorationLine: "none",
  },
});
