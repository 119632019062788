import React from "react";
import { View, Pressable, StyleSheet } from "react-native";
import { MotiView } from "moti";
import { RightArrow2, JourneyPathBold, Sparkle } from "../../svgs/common";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/string";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import { formatDateAsDayOrFutureDate } from "../../../utils/date";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { shadowStyles } from "../../../styles/shadow";
import Tooltip from "../../common/tooltip/Tooltip";
import { useChatBar, ConversationType, Context } from "../../../contexts/ChatBarContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useWebSocket from "../../../hooks/useWebSocket";
import { useRoute } from "@react-navigation/native";

function DashboardTask({
  title,
  onJourneyPathPress,
  completed,
  priority = "medium",
  date,
  isPartOfJourney,
  onTaskPress,
  isJourneyView,
  isDeleted,
  isMoved,
  journeyId,
}) {
  const { isMedium } = useResponsiveScreen();
  const formattedTitle = capitalizeFirstLetterOfEachWord(title);
  const formattedPriority = capitalizeFirstLetterOfEachWord(priority);

  const { sendMessage } = useWebSocket();
  const route = useRoute();
  const formattedDate = formatDateAsDayOrFutureDate(new Date(date));
  const {
    setShowChat,
    setMetaIsLoading,
    mapRouteToContext,
    setJourneyMessage,
    setConversationType,
    setActiveContextAndObjectId,
  } = useChatBar();

  const getPriorityText = (priority) => {
    switch (priority) {
      case "low":
        return "#3B7C0F"; // Green
      case "medium":
        return "#B54708"; // Yellow
      case "high":
        return "#C01048"; // Red
      default:
        return "#CCC"; // Default color
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "low":
        return "#E3FBCC"; // Green
      case "medium":
        return "#FEF0C7"; // Yellow
      case "high":
        return "#FFE4E8"; // Red
      default:
        return "#CCC"; // Default color
    }
  };

  const handleGetHelpFromAxioJourney = async () => {
    const token = await AsyncStorage.getItem("token");
    setConversationType(ConversationType.JOURNEY);
    setJourneyMessage({ journeyId });
    setActiveContextAndObjectId({
      context: Context.JOURNEY,
      objectId: journeyId,
    });

    sendMessage({
      meta: {
        token: token,
        abort: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isLoading: true,
      },
      state: {
        general: ConversationType.JOURNEY,
        context: Context.JOURNEY,
        params: { id: journeyId },
        journey: {
          journeyId: journeyId,
        },
      },
      content: {
        message: "Help me get started with this journey task: " + title,
        author: "HUMAN",
        type: null,
      },
    });
    setMetaIsLoading(true);
    setShowChat(true);
    // onClose();
  };

  const handleGetHelpFromAxio = async () => {
    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isLoading: true,
      },
      state: {
        general: "GENERAL_CONVERSATION",
        context: mapRouteToContext(route.name),
        params: route.params,
      },
      content: {
        message: "Help me get started with the task " + title,
        author: "HUMAN",
        type: null,
      },
    });
    setMetaIsLoading(true);
    setShowChat(true);
    // onClose();
  };

  return (
    <>
      {isDeleted && (
        <View style={[isMedium ? styles.card : styles.cardMobile, styles.deletedTask]}>
          <CustomText text="Task Deleted" style={[styles.cardText, { color: "#C01048" }]} />
        </View>
      )}
      {(isMoved === "Active" || isMoved === "Completed") && (
        <View style={[isMedium ? styles.card : styles.cardMobile, styles.movedTask]}>
          <CustomText text={`Task moved to “${isMoved} Tasks”`} style={[styles.cardText, { color: "#3B7C0F" }]} />
        </View>
      )}
      {!isDeleted && !isMoved && (
        <Pressable onPress={onTaskPress} style={{ flex: 1 }}>
          <MotiView
            from={{
              opacity: 0,
              translateX: -400,
            }}
            animate={{
              opacity: 1,
              translateX: 0,
            }}
            delay={200}
            transition={{ type: "spring" }}
            aria-label={title}>
            <View
              style={[
                styles.card,
                shadowStyles.primary,
                {
                  backgroundColor: "#fff",
                  overflow: "visible",
                },
              ]}>
              <View styles={styles.column}>
                <View style={styles.wrapper}>
                  <View
                    style={[
                      styles.roundedLabel,
                      { backgroundColor: getPriorityColor(priority) }, // Rounded label color based on priority
                    ]}>
                    <CustomText
                      text={`${formattedPriority} Priority`}
                      style={{ color: getPriorityText(priority) }}
                      size="xs"
                      weight="bold"
                    />
                  </View>
                </View>
                <CustomSpacing size="s" />
                {!completed && (
                  <CustomText
                    useTranslationText={false}
                    text={`Task due ${formattedDate}`}
                    numberOfLines={2}
                    ellipsizeMode="tail"
                    size="l"
                    style={{ color: "#101828" }}
                    weight="bold"
                  />
                )}
              </View>

              <View style={styles.column}>
                <CustomText
                  useTranslationText={false}
                  text={formattedTitle}
                  numberOfLines={2}
                  ellipsizeMode="tail"
                  size="l"
                  style={{ color: "#667085" }}
                  weight="bold"
                  role="header"
                />
                <CustomSpacing size="l" />
                <CustomSpacing size="l" />
                <View style={styles.bottomRow}>
                  <View>
                    <CustomButton
                      text="View task"
                      accessibilityHint="Opens the task details"
                      styleType="primaryTransparent"
                      onPress={onTaskPress}
                      textStyle={[styles.viewButton]}
                      style={{ padding: 0, gap: 3 }}
                      rightIcon={<RightArrow2 />}
                      bold
                    />
                  </View>
                  <View style={{ flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                    <View style={{ marginRight: 5 }}>
                      {isPartOfJourney && !isJourneyView && (
                        <Tooltip content="View Journey" offset={0}>
                          <Pressable
                            role="button"
                            aria-label="View journey path"
                            accessibilityHint="Opens the journey path details"
                            onPress={onJourneyPathPress}>
                            <JourneyPathBold fill={"#182230"} />
                          </Pressable>
                        </Tooltip>
                      )}
                    </View>
                    <View>
                      <Tooltip content="Get help from Axio" offset={10} horizontalOffset={-60}>
                        <Pressable
                          role="button"
                          aria-label="Get help from Axio"
                          onPress={isPartOfJourney ? handleGetHelpFromAxioJourney : handleGetHelpFromAxio}>
                          <Sparkle fill={"#182230"} />
                        </Pressable>
                      </Tooltip>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </MotiView>
        </Pressable>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  card: {
    padding: 20,
    margin: 15,
    borderRadius: 24.391,
    elevation: 5,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: 260,
    width: 245,
    marginRight: 16,
  },
  column: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
  },
  bottomRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
  },
  roundedLabel: {
    borderRadius: 100,
    paddingHorizontal: 13,
    paddingVertical: 8,
    backgroundColor: "#fff",
  },
  wrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },
  viewButton: {
    fontFamily: "Inter",
    fontSize: 12,
    lineHeight: 20,
    fontWeight: 700,
    color: "#182230",
  },
  deletedTask: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFD2D2",
  },
  movedTask: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E3FBCC",
  },
  cardText: {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 24,
  },
});

export default DashboardTask;
