import React, { useEffect, useMemo } from "react";
import DarkChatMenu from "../../../components/chatmenu/DarkChatMenu/DarkChatMenu";
import LightAssignmentMenu from "../../../components/chatmenu/LightAssignmentMenu/LightAssignmentMenu";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { useSchoolsQuery, Chat, useGetAiTutorQuery, useGetCourseQuery } from "../../../graphql/generated/graphql";
import { Context, useChatBar, ConversationType } from "../../../contexts/ChatBarContext";
import { RootStackParamList } from "../../../navigation/AppNavigator.web";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import useWebSocket from "../../../hooks/useWebSocket";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import { useAppConfig } from "../../../AppConfigProvider";
import ProgressDarkChatMenu from "../../../components/chatmenu/ProgressDarkChatMenu/ProgressDarkChatMenu";
import ProgressLightMenu from "../../../components/chatmenu/ProgressLightMenu/ProgressLightMenu";

const ChatMenuContainer = ({ chatsData, onChatPress, onMobileChatPress }) => {
  const { isMedium } = useResponsiveScreen();
  const appConfig = useAppConfig();
  const isAxioOffering = appConfig.isAxioOffering;
  const route = useRoute<RouteProp<RootStackParamList, "Custom Resource Student">>();

  const {
    setContext,
    setShowChat,
    mapRouteToContext,
    state: chatBarState,
    setActiveContextAndObjectId,
    setConversationType,
    setJourneyMessage,
    setSchoolMessage,
  } = useChatBar();

  const { sendMessage } = useWebSocket();
  const { state: appState, dispatch } = useAppState();

  const chats: { [key: string]: Chat[] } = useMemo(() => {
    const groupedChats = chatsData?.getChats?.reduce((acc, chat) => {
      const group = chat?.context;
      acc[group] = acc[group] || [];
      acc[group].push(chat);
      return acc;
    }, {});
    return groupedChats;
  }, [chatsData]);

  const { data: schoolsData, loading: schoolsLoading } = useSchoolsQuery();
  const isConnectedSchool = !schoolsLoading && schoolsData?.getSchools.length > 0;

  const {
    data: aiTutorData,
    loading: aiTutorLoading,
    refetch: refetchAiTutorData,
  } = useGetAiTutorQuery({
    variables: { id: chatBarState.activeObjectId },
    skip: chatBarState.activeContext !== Context.AITUTOR,
  });

  const { refetch: refetchCourseData } = useGetCourseQuery({
    variables: { id: route.params?.course_id },
    skip: !route.params?.course_id,
  });

  let objectiveData = [];
  if (aiTutorData && !aiTutorLoading) {
    objectiveData = aiTutorData?.getAITutor.data[0].objectives.map((objective) => ({
      id: objective.id,
      title: objective.objective,
      inProgress: objective.status == "inProgress" ? true : false,
      isComplete: objective.status == "completed" ? true : false,
    }));
  }

  const { name } = useRoute();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  const activeContext = useMemo(() => {
    return mapRouteToContext(name as keyof RootStackParamList);
  }, [name]);

  useEffect(() => {
    if (chatBarState.activeObjectId && chatBarState.activeContext === Context.AITUTOR) {
      refetchAiTutorData();
      refetchCourseData();
    }
  }, [appState.content.type]);

  const handleViewJournal = () => {
    navigation.navigate("Journal");
    setShowChat(false);
  };

  const handleViewJourneys = () => {
    navigation.navigate("Productivity", { tab: "journeys" });
    setShowChat(false);
  };

  const handleViewChatPress = (id) => {
    const chat = chatsData?.getChats.find((chat) => chat.id === id);

    if (!chat) return;

    const chatParams = chat.params;

    if (chat.context === Context.ASSIGNMENT) {
      navigation.navigate("Resource", {
        resource_type: chatParams.resource_type,
        resource_id: chatParams.resource_id,
        course_id: chatParams.course_id,
        integration_id: chatParams.integration_id,
      });
    } else if (chat.context === Context.JOURNEY) {
      navigation.navigate("Journey", {
        id: chatParams.id,
      });
    }

    setShowChat(false);
  };

  const handleChatPress = async (id) => {
    const chat = chatsData?.getChats.find((chat) => chat.id === id);

    if (!chat) return;

    onChatPress(chat);

    dispatch({
      type: actions.SET_CONTENT,
      payload: { message: "" },
    });

    setConversationType(
      chat.context === Context.ASSIGNMENT || chat.context === Context.AITUTOR
        ? ConversationType.SCHOOL
        : ConversationType.JOURNEY
    );

    if (chat.context === Context.JOURNEY) {
      setJourneyMessage({
        journeyId: chat.objectId,
      });
    }

    if (chat.context === Context.ASSIGNMENT || chat.context === Context.AITUTOR) {
      const { resource_id, course_id, integration_id, resource_type } = chat.params;

      setSchoolMessage({
        contentType: resource_type,
        contentId: resource_id,
        classId: course_id,
        integrationId: integration_id,
      });
    }

    setContext({
      context: chat.context as Context,
      params: chat.params as { [key: string]: string },
    });
    setActiveContextAndObjectId({
      context: chat.context as Context,
      objectId: chat.objectId,
    });

    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: true,
      },
    });

    if (!isMedium) {
      onMobileChatPress();
    }
  };

  const handleViewClasses = () => {
    navigation.navigate("Coursework");
    setShowChat(false);
  };

  if (activeContext === Context.GENERAL) {
    return isAxioOffering ? (
      <ProgressDarkChatMenu onViewJournal={handleViewJournal} />
    ) : (
      <DarkChatMenu
        assignmentChats={chats?.assignment}
        journeyChats={chats?.journey}
        onViewJournal={handleViewJournal}
        onViewClasses={handleViewClasses}
        activeObjectId={chatBarState?.activeObjectId || ""}
        onViewChatPress={handleViewChatPress}
        onChatPress={handleChatPress}
        onViewJourneys={handleViewJourneys}
        isConnectedSchool={isConnectedSchool}
      />
    );
  } else if (activeContext === Context.ASSIGNMENT) {
    return isAxioOffering ? (
      <ProgressLightMenu objectivesData={objectiveData} />
    ) : (
      <LightAssignmentMenu
        assignmentChats={chats?.assignment}
        objectivesData={objectiveData}
        onViewChatPress={handleViewChatPress}
        activeObjectId={chatBarState?.activeObjectId || ""}
        onChatPress={handleChatPress}
      />
    );
  } else if (activeContext === Context.AITUTOR) {
    return <ProgressLightMenu objectivesData={objectiveData} />;
  } else if (activeContext === Context.JOURNEY) {
    return isAxioOffering ? (
      <ProgressLightMenu objectivesData={objectiveData} />
    ) : (
      <LightAssignmentMenu
        assignmentChats={chats?.assignment}
        objectivesData={objectiveData}
        onViewChatPress={handleViewChatPress}
        activeObjectId={chatBarState?.activeObjectId || ""}
        onChatPress={handleChatPress}
      />
    );
  }
};

export default ChatMenuContainer;
