import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function TrashCanBlue(props) {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#clip0_436_72)">
        <Path
          d="M13.5 3h-2.25v-.75A1.75 1.75 0 009.5.5h-3a1.75 1.75 0 00-1.75 1.75V3H2.5a.75.75 0 000 1.5h.25V13A1.25 1.25 0 004 14.25h8A1.25 1.25 0 0013.25 13V4.5h.25a.75.75 0 100-1.5zm-7.25-.75A.25.25 0 016.5 2h3a.25.25 0 01.25.25V3h-3.5v-.75zm5.5 10.5h-7.5V4.5h7.5v8.25zM7.25 6.5v4a.75.75 0 11-1.5 0v-4a.75.75 0 011.5 0zm3 0v4a.75.75 0 11-1.5 0v-4a.75.75 0 011.5 0z"
          fill="#3E68FE"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_436_72">
          <Path fill="#fff" d="M0 0H16V16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default TrashCanBlue;
