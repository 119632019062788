import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function Sparkle(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#clip0_123_414)">
        <Path
          d="M15.547 9.79L11.65 8.353l-1.44-3.9a1.556 1.556 0 00-2.92 0l-1.437 3.9-3.9 1.437a1.557 1.557 0 000 2.92l3.897 1.437 1.44 3.9a1.556 1.556 0 002.92 0l1.437-3.897 3.9-1.44a1.557 1.557 0 000-2.92zm-4.952 2.747a.938.938 0 00-.555.555l-1.29 3.492-1.287-3.492a.937.937 0 00-.555-.555L3.416 11.25l3.492-1.287a.938.938 0 00.555-.555L8.75 5.916l1.287 3.492a.937.937 0 00.555.555l3.492 1.287-3.489 1.287zm.342-9.412a.938.938 0 01.938-.938h.938V1.25a.938.938 0 011.874 0v.938h.938a.938.938 0 010 1.874h-.938V5a.937.937 0 11-1.874 0v-.938h-.938a.937.937 0 01-.938-.937zm8.75 3.75a.937.937 0 01-.937.938h-.313v.312a.937.937 0 11-1.875 0v-.313h-.312a.937.937 0 010-1.875h.313v-.312a.938.938 0 011.875 0v.313h.312a.938.938 0 01.938.937z"
          fill="#98A2B3"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_123_414">
          <Path fill="#fff" d="M0 0H20V20H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default Sparkle;
