import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Remove(props) {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M13.03 11.97a.751.751 0 01-1.062 1.062L8 9.062l-3.97 3.969a.751.751 0 11-1.062-1.063L6.938 8 2.968 4.03a.751.751 0 111.063-1.062L8 6.938l3.97-3.97a.751.751 0 011.062 1.062L9.062 8l3.969 3.97z"
        fill="#344054"
      />
    </Svg>
  );
}

export default Remove;
