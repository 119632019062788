import { View, StyleSheet, Platform } from "react-native";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { useEffect, useState } from "react";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { MagnifyingGlass } from "../../../components/svgs/common";
import CoursesTable from "./CoursesTable";
import { PlusCircle } from "../../../components/svgIcons";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";
import {
  useSchoolsQuery,
  useGetCoursesBySchoolByQueryQuery,
  useAssignCourseToFacultyMutation,
  useCreateCoursePlaceholderMutation,
  useRemoveCourseAssignmentFromFacultyMutation,
  useDeleteCourseCascadeMutation,
  GetCoursesBySchoolByQueryDocument,
} from "../../../graphql/generated/graphql";
import Pagination from "../../../components/common/Pagination/Pagination";
import NewCustomAxioActionModal from "../UsersTabContainer/NewCustomAxioActionModal";
import AssignFacultyToCourseListContainer from "./AssignFacultyToCourseListContainer";
import RemoveFacultyFromCourseListContainer from "./RemoveFacultyFromCourseListContainer";
import AddCourseModal from "./AddCourseModalContainer";
import { NewCustomActionButton } from "../../../components/common/general/CustomButton/NewCustomActionButton";

const modals = {
  removeTeacher: {
    title: "Remove Faculty from Course?",
    ariaLabel: "Remove Faculty From Course Modal",
    warningText:
      "Following faculty will no longer have access to the course. This action cannot be undone, and all associated data will be deleted.",
    actionButtonText: "Remove from Course",
  },
  deleteCourse: {
    title: "Delete Course?",
    ariaLabel: "Delete Course Modal",
    warningText:
      "Are you sure you want to permanently delete this course? This action cannot be undone, and all associated data will be deleted.",
    actionButtonText: "Delete Course",
  },
  deleteConfirmation: {
    title: "Course Deleted",
    ariaLabel: "Course Deleted Modal",
    warningText: "The course has been successfully deleted.",
    actionButtonText: "OK",
  },
};

export enum CoursesTabModalState {
  CLOSED = "CLOSED",
  DELETE_COURSE = "DELETE_COURSE",
  DELETE_CONFIRMATION = "DELETE_CONFIRMATION",
  ASSIGN_FACULTY = "ASSIGN_FACULTY",
  REMOVE_FACULTY = "REMOVE_FACULTY",
  ADD_COURSE = "ADD_COURSE",
}

const CoursesTabContainer = ({customColor}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { isXLarge } = useResponsiveScreen();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [modalState, setModalState] = useState({
    state: CoursesTabModalState.CLOSED,
    courseId: "",
    courseName: "",
  });

  const { data: schoolsData, loading: schoolsLoading } = useSchoolsQuery();

  const {
    data: coursesData,
    loading: coursesLoading,
    refetch: coursesRefetch,
  } = useGetCoursesBySchoolByQueryQuery({
    variables: {
      schoolId: schoolsData?.getSchools[0].id,
      query: searchQuery,
      page: page,
      pageSize: pageSize,
    },
  });

  const refetchQueries = [
    {
      query: GetCoursesBySchoolByQueryDocument,
      variables: {
        schoolId: schoolsData?.getSchools[0].id,
        query: searchQuery,
        page: page,
        pageSize: pageSize,
      },
    },
  ];

  const [assignCourseToFaculty] = useAssignCourseToFacultyMutation();
  const [removeCourseFromFaculty] = useRemoveCourseAssignmentFromFacultyMutation();
  const [createCoursePlaceholder] = useCreateCoursePlaceholderMutation();
  const [deleteCourseCascade] = useDeleteCourseCascadeMutation();

  const addCourse = async ({
    courseName,
    courseType,
  }: {
    courseName: string;
    courseType: "Core" | "General" | "Elective";
  }) => {
    try {
      const result = await createCoursePlaceholder({
        variables: {
          name: courseName,
          type: courseType,
          schoolId: schoolsData?.getSchools[0].id,
        },
        refetchQueries: refetchQueries,
      });

      return result.data;
    } catch (error) {
      return false;
    }
  };

  const assignFaculty = async (facultyId: string) => {
    await assignCourseToFaculty({
      variables: {
        courseId: modalState.courseId,
        userId: facultyId,
      },
      refetchQueries: refetchQueries,
    });
    setModalState({ state: CoursesTabModalState.CLOSED, courseId: "", courseName: "" });
  };

  const removeFaculty = async (facultyId: string) => {
    await removeCourseFromFaculty({
      variables: {
        courseId: modalState.courseId,
        userId: facultyId,
      },
      refetchQueries: refetchQueries,
    });
    setModalState({ state: CoursesTabModalState.CLOSED, courseId: "", courseName: "" });
  };

  const handleDeleteCourse = async () => {
    await deleteCourseCascade({
      variables: {
        id: modalState.courseId,
      },
      refetchQueries: refetchQueries,
    });
    setModalState({ state: CoursesTabModalState.DELETE_CONFIRMATION, courseId: "", courseName: "" });
    setSearchQuery("");
  };

  const handleActionModalOpen = async (courseId: string, courseName: string, modalType: CoursesTabModalState) => {
    setModalState({ state: modalType, courseId: courseId, courseName: courseName });
  };

  const handleModalClose = () => {
    setModalState({ state: CoursesTabModalState.CLOSED, courseId: "", courseName: "" });
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= coursesData?.getCoursesBySchoolByQuery?.data.totalCount) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  useEffect(() => {
    coursesRefetch();
  }, [searchQuery, page, pageSize]);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.headerRow}>
            <CustomText text="View and Manage Courses" size="l" weight="bold" style={styles.headerText} />
            <NewCustomActionButton
              text="Add a Course"
              onPress={() => setModalState({ state: CoursesTabModalState.ADD_COURSE, courseId: "", courseName: "" })}
              leftIcon={<PlusCircle color={customColor} />}
            />
          </View>
          <View style={styles.headerRow}>
            <View style={styles.subHeaderContainer}>
              <CustomText text="Total Courses" size="m" weight="semiBold" style={styles.subHeaderText} />
              <CustomText
                text={`(${coursesData?.getCoursesBySchoolByQuery?.count})`}
                size="m"
                weight="semiBold"
                style={styles.subHeaderText}
                useTranslationText={false}
              />
            </View>
            <View style={styles.searchContainer}>
              <MagnifyingGlass style={styles.searchIcon} />
              <CustomTextInput
                placeholder="Search by any value"
                value={searchQuery}
                onChangeText={setSearchQuery}
                style={[
                  styles.searchInput,
                  Platform.select({
                    web: {
                      outlineStyle: "none",
                      outlineWidth: 0,
                      WebkitAppearance: "none",
                    } as any,
                  }),
                ]}
                placeholderTextColor="#9CA3AF"
                selectionColor="#6B7280"
                cursorColor="#6B7280"
              />
            </View>
          </View>
        </View>
        <CoursesTable
          courses={coursesData?.getCoursesBySchoolByQuery?.data.courses || []}
          onActionModalOpen={handleActionModalOpen}
        />
        <Pagination
          page={page}
          handlePageChange={handlePageChange}
          totalPageCount={Math.ceil(coursesData?.getCoursesBySchoolByQuery?.data.totalCount / pageSize)}
        />
      </View>
      <NewCustomAxioActionModal
        visible={modalState.state === CoursesTabModalState.DELETE_COURSE}
        onClose={handleModalClose}
        onAction={handleDeleteCourse}
        modalTexts={modals.deleteCourse}
        contextualText={modalState.courseName}
      />
      <NewCustomAxioActionModal
        visible={modalState.state === CoursesTabModalState.DELETE_CONFIRMATION}
        onClose={handleModalClose}
        modalTexts={modals.deleteConfirmation}
        contextualText={modalState.courseName}
      />
      <AssignFacultyToCourseListContainer
        visible={modalState.state === CoursesTabModalState.ASSIGN_FACULTY}
        onClose={handleModalClose}
        onAction={assignFaculty}
      />
      <RemoveFacultyFromCourseListContainer
        visible={modalState.state === CoursesTabModalState.REMOVE_FACULTY}
        onClose={handleModalClose}
        onAction={removeFaculty}
        courseId={modalState.courseId}
      />
      <AddCourseModal
        visible={modalState.state === CoursesTabModalState.ADD_COURSE}
        onClose={handleModalClose}
        onSubmit={addCourse}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: "#fff",
    borderRadius: 8,
    flex: 1,
  },
  header: {
    flexDirection: "column",
    width: "100%",
    marginBottom: 24,
    gap: 24,
  },
  headerRow: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 16,
  },
  headerText: {
    color: "#000",
  },
  subHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  subHeaderText: {
    color: "#000",
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    borderRadius: 6,
    padding: 4,
  },
  searchIcon: {
    marginRight: 8,
    color: "#3e68fe",
  },
  searchInput: {
    borderWidth: 0,
    backgroundColor: "transparent",
    width: 200,
    padding: 8,
    fontSize: 14,
    color: "#374151",
  },
});

export default CoursesTabContainer;
