import { Svg, Path } from "react-native-svg";

const BadgeElective = (props) => (
  <Svg width="14" height="14" viewBox="0 0 14 14" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M7.7765 2.43182C7.77804 2.00292 7.43162 1.65395 7.00271 1.65235C6.57381 1.65075 6.22479 1.99734 6.22312 2.42625C6.218 3.8543 5.84492 4.7532 5.27073 5.30781C4.69016 5.86859 3.77389 6.20371 2.41825 6.22295C1.99149 6.22901 1.64958 6.57828 1.65261 7.00508C1.65564 7.43187 2.00248 7.77626 2.42928 7.77626C3.93513 7.77626 4.82961 8.17403 5.36386 8.75178C5.91002 9.34241 6.21134 10.2617 6.22315 11.5771C6.22692 11.9971 6.5639 12.338 6.98383 12.3466C7.40376 12.3553 7.75448 12.0285 7.77553 11.609C7.84526 10.219 8.2148 9.28604 8.78872 8.70092C9.35311 8.12551 10.229 7.77119 11.5674 7.77625C11.9937 7.77786 12.3416 7.43559 12.347 7.00934C12.3523 6.58309 12.0131 6.23217 11.587 6.22305C10.1184 6.19164 9.20257 5.80334 8.64748 5.22682C8.0907 4.64854 7.77137 3.75048 7.7765 2.43182Z"
      fill={props.fill}
    />
  </Svg>
);

export default BadgeElective;
