import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Cloud(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M16.72 15.53L15 13.81v5.69a.75.75 0 11-1.5 0v-5.69l-1.72 1.72a.75.75 0 11-1.06-1.06l3-3a.749.749 0 011.06 0l3 3a.75.75 0 11-1.06 1.06zM15 3.75a8.258 8.258 0 00-7.38 4.564 6 6 0 10-.87 11.936h3.75a.75.75 0 100-1.5H6.75a4.5 4.5 0 11.308-8.989A8.25 8.25 0 006.75 12a.75.75 0 101.5 0 6.75 6.75 0 119.45 6.188.75.75 0 10.6 1.374A8.25 8.25 0 0015 3.75z"
        fill="#3E68FE"
      />
    </Svg>
  );
}

export default Cloud;
