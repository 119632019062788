import * as React from "react";
import Svg, { Path } from "react-native-svg";

function LinkBlue(props) {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.17 9.889l2.333 2.333L20.226 7.5a5.85 5.85 0 018.273 8.273l-4.723 4.722 2.334 2.334 4.722-4.723a9.15 9.15 0 00-12.94-12.94L13.17 9.89zM7.498 20.227l3.975-3.974-2.334-2.334-3.975 3.975a9.15 9.15 0 1012.94 12.94l3.975-3.975-2.333-2.333L15.77 28.5a5.85 5.85 0 01-8.273-8.274z"
        fill="#3E68FE"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.531 22.137a1.65 1.65 0 102.334 2.333L24.47 13.864a1.65 1.65 0 10-2.333-2.334L11.531 22.137z"
        fill="#3E68FE"
      />
    </Svg>
  );
}

export default LinkBlue;
