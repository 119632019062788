import { View, StyleSheet } from "react-native";
import BlankLayout from "../layouts/BlankLayout";
import LoginFormContainer from "../containers/auth/LoginFormContainer/LoginFormContainer";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { useAuth } from "../hooks/useAuth";
import { useNavigation } from "@react-navigation/native";
import { useAppConfig } from "../AppConfigProvider";
import { useEffect } from "react";

function LoginView(isLMS = false) {
  const { isLarge } = useResponsiveScreen();
  const { isAuthenticated, isEmailVerified, otpVerifiedForSession } = useAuth();
  const navigation = useNavigation();
  const appConfig = useAppConfig();
  const isAxioOffering = appConfig.isAxioOffering;

  useEffect(() => {
    if (isAuthenticated && isEmailVerified && otpVerifiedForSession) {
      if (isAxioOffering) {
        navigation.navigate("Custom School Student");
      } else {
        navigation.navigate("My Day");
      }
    }
  }, [isAuthenticated, isEmailVerified, otpVerifiedForSession, navigation]);

  return isLarge ? (
    <BlankLayout>
      <View style={[styles.webContainer, { width: !isLMS ? "40%" : "100%" }]}>
        <LoginFormContainer />
      </View>
    </BlankLayout>
  ) : (
    <BlankLayout>
      <View style={styles.mobileContainer}>
        <View style={{ padding: 15 }}>
          <LoginFormContainer />
        </View>
      </View>
    </BlankLayout>
  );
}

const styles = StyleSheet.create({
  webContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 30,
  },
  mobileContainer: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",

    width: "100%",
  },
});

export default LoginView;
