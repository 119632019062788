import { View } from "moti";
import { MagnifyingGlass } from "../../../svgs/common";
import CustomTextInput from "../CustomTextInput/CustomTextInput";
import { Platform, StyleSheet } from "react-native";

const CustomSearchInput = ({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
}) => {
  return (
    <View style={styles.searchContainer}>
      <MagnifyingGlass style={styles.searchIcon} />
      <CustomTextInput
        placeholder="Search by any value"
        value={searchQuery}
        onChangeText={setSearchQuery}
        style={[
          styles.searchInput,
          Platform.select({
            web: {
              outlineStyle: "none",
              outlineWidth: 0,
              WebkitAppearance: "none",
            } as any,
          }),
        ]}
        placeholderTextColor="#9CA3AF"
        selectionColor="#6B7280"
        cursorColor="#6B7280"
      />
    </View>
  );
};

export default CustomSearchInput;

const styles = StyleSheet.create({
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    borderRadius: 6,
    padding: 4,
  },
  searchIcon: {
    marginRight: 8,
    color: "#3e68fe",
  },
  searchInput: {
    borderWidth: 0,
    backgroundColor: "transparent",
    width: 200,
    padding: 8,
    fontSize: 14,
    color: "#374151",
  },
});
