import React, { useState, useRef, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import AnalyticsAccordionList from "../../../components/analytics/AnalyticsAccordionList.js/AnalyticsAccordionList";

function AccordionAnalyticsContainer({ data, onListDrillDown, breadcrumb }) {
  return (
    <View style={styles.container}>
      <AnalyticsAccordionList data={data} onListDrillDown={onListDrillDown} breadcrumb={breadcrumb} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default AccordionAnalyticsContainer;
