import React from "react";
import { StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { BlackInfo } from "../../svgs/common";
import CustomText from "../../common/general/CustomText/CustomText";

const DeleteResource = ({ deleteButtonClick, backButtonClick, type }) => {
  return (
    <View style={styles.container}>
      <View style={styles.warningBox}>
        <View style={{ marginRight: 10 }}>
          <BlackInfo />
        </View>
        <CustomText
          style={styles.warningBoxText}
          size="xs"
          text={`Are you sure you want to delete this ${type}? This action cannot be undone.`}
        />
      </View>
      <View style={styles.buttonContainer}>
        <CustomButton
          text="Delete"
          onPress={deleteButtonClick}
          styleType="danger"
          size="m"
          style={{ marginBottom: 10 }}
          bold="true"
          aria-label={`Delete ${type}`}
        />
        <CustomButton
          text="Cancel"
          onPress={backButtonClick}
          styleType="primaryLight"
          size="m"
          style={styles.backButton}
          bold="true"
          aria-label="Cancel"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    padding: 20,
  },
  buttonContainer: {
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    borderColor: "#3E68FE",
    borderWidth: 2,
    borderRadius: 10,
  },
  warningBox: {
    padding: 10,
    backgroundColor: "rgba(247, 92, 138, 0.2)",
    borderRadius: 10,
    marginBottom: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    flexDirection: "row",
    alignItems: "center",
  },
  warningBoxText: {
    color: "#F75C8A",
  },
});

export default DeleteResource;
