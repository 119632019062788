import React from "react";
import List from "./List";
import { View } from "react-native";
import { EditTask, TrashCan, ThreeDot } from "../../svgs/common";

export default {
  title: "Admin/List",
  tags: ["autodocs"],
  component: List,
  argTypes: {
    items: { control: "object" },
  },
  decorators: [
    (Story) => (
      <View style={{ padding: 20 }}>
        <Story />
      </View>
    ),
  ],
};

const Template = (args) => <List {...args} />;

export const Default = Template.bind({});
Default.args = {
  items: [
    {
      title: "Row 1",
      rowItems: [{ text: "Item 1" }, { text: "Item 2" }, { text: "Item 3" }],
      icon: null,
      buttons: [
        { icon: <EditTask />, onPress: () => console.log("Edit clicked") },
        { icon: <ThreeDot />, onPress: () => console.log("Options clicked") },
      ],
    },
    {
      title: "Row 2",
      rowItems: [{ text: "Data A" }, { text: "Data B" }, { text: "Data C" }],
      icon: null,
      buttons: [
        { icon: <TrashCan />, onPress: () => console.log("Delete clicked") },
        { icon: <EditTask />, onPress: () => console.log("Edit clicked") },
      ],
    },
  ],
};
