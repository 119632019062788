import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";
import { MagnifyingGlass } from "../../../components/svgs/common";
import { DataTable } from "react-native-paper";
import { UsersTabModalState } from "../../../containers/settings/UsersTabContainer/UsersTabContainer";
import { CoursesTabModalState } from "../../../containers/settings/CoursesTabContainer/CoursesTabContainer";
import { PlusCircle } from "../../../components/svgIcons";
import Delete from "../../../components/svgs/common/Delete";
import { NewCustomActionButton } from "../../../components/common/general/CustomButton/NewCustomActionButton";
import Pill from "../Pill/Pill";

type SearchListModalProps<T> = {
  visible: boolean;
  onClose: () => void;
  onAction: (id: string) => void;
  list: { id: string; name: string; icon?: JSX.Element; fill?: string }[];
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  modalType: T;
};

const SearchListModal = <T,>({
  visible,
  onClose,
  onAction,
  list,
  searchQuery,
  setSearchQuery,
  modalType,
}: SearchListModalProps<T>) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={false}
      title={undefined}
      exitFill="#6B7280"
      onShow={() => {}}
      ariaLabel="Search For Assignment Action Modal"
      style={{ width: isMedium ? "50%" : "90%" }}>
      <View style={styles.container}>
        <View style={styles.searchContainer}>
          <MagnifyingGlass style={styles.searchIcon} />
          <CustomTextInput
            placeholder="Search by any value"
            value={searchQuery}
            onChangeText={setSearchQuery}
            style={[
              styles.searchInput,
              Platform.select({
                web: {
                  outlineStyle: "none",
                  outlineWidth: 0,
                  WebkitAppearance: "none",
                } as any,
              }),
            ]}
            placeholderTextColor="#9CA3AF"
            selectionColor="#6B7280"
            cursorColor="#6B7280"
          />
        </View>
        <DataTable>
          {list.map((item) => (
            <DataTable.Row key={item.id}>
              <DataTable.Cell
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <View
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingVertical: 12,
                  }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 8,
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}>
                    <Pill leftIcon={item.icon} backgroundColor={item.fill} />
                    <CustomText text={item.name} size="s" weight="medium" useTranslationText={false} style={{}} />
                  </View>
                  <NewCustomActionButton
                    text={
                      modalType === UsersTabModalState.ASSIGN_COURSE ||
                      modalType === CoursesTabModalState.ASSIGN_FACULTY
                        ? "Assign"
                        : "Remove"
                    }
                    onPress={async () => {
                      await onAction(item.id);
                      onClose();
                    }}
                    leftIcon={
                      modalType === UsersTabModalState.ASSIGN_COURSE ||
                      modalType === CoursesTabModalState.ASSIGN_FACULTY ? (
                        <PlusCircle color="#3E68FE" />
                      ) : (
                        <Delete color="#3E68FE" />
                      )
                    }
                  />
                </View>
              </DataTable.Cell>
            </DataTable.Row>
          ))}
        </DataTable>
      </View>
    </CustomModal>
  );
};

export default SearchListModal;

const styles = StyleSheet.create({
  container: {
    padding: 0,
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  searchIcon: {
    width: 20,
    height: 20,
  },
  searchInput: {
    flex: 1,
  },
});
