import * as React from "react";
import Svg, { Path } from "react-native-svg";

function DocumentBlue(props) {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M30.444 11.182l-7.875-7.875a1.687 1.687 0 00-1.194-.494h-13.5a2.813 2.813 0 00-2.813 2.812v24.75a2.812 2.812 0 002.813 2.813h20.25a2.812 2.812 0 002.813-2.813v-18c0-.447-.178-.876-.494-1.193zM22.5 8.016l3.234 3.234H22.5V8.016zM8.437 29.813V6.188h10.688v6.75a1.687 1.687 0 001.688 1.687h6.75v15.188H8.438zm15.75-11.25A1.687 1.687 0 0122.5 20.25h-9a1.687 1.687 0 110-3.375h9a1.687 1.687 0 011.688 1.688zm0 5.625a1.687 1.687 0 01-1.687 1.687h-9a1.687 1.687 0 110-3.375h9a1.687 1.687 0 011.688 1.688z"
        fill="#3E68FE"
      />
    </Svg>
  );
}

export default DocumentBlue;
