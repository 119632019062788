import * as React from "react";
import Svg, { Path, Circle, Mask, G, Rect } from "react-native-svg";
import { v4 as uuidv4 } from "uuid";

function BelhavenLogo(props) {
  const id1 = uuidv4();
  const id2 = uuidv4();
  return (
    <Svg width={182} height={57} viewBox="0 0 182 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M0 56.35V38.59h2.978v15.253h7.313v2.507H0zM17.327 56.35V38.59h2.978v17.76h-2.978zM32.73 56.402c-3.24 0-5.12-1.933-5.12-5.145v-7.575c0-3.212 1.88-5.145 5.12-5.145h2.977c3.239 0 5.12 1.933 5.12 5.145v.758h-2.978v-.758c0-1.723-.915-2.638-2.56-2.638h-2.142c-1.645 0-2.56.915-2.56 2.639v7.6c0 1.698.915 2.612 2.56 2.612h2.168c1.672 0 2.586-.784 2.586-2.481v-2.116h-3.474v-2.35h6.4v4.44c0 3.212-1.881 5.014-5.12 5.014h-2.978zM48.155 56.35V38.59h2.978v7.417h7.757v-7.418h2.977V56.35H58.89v-7.836h-7.757v7.836h-2.978zM73.564 56.35V41.097h-4.702v-2.508h12.355v2.508H76.54V56.35h-2.977zM88.217 56.35V38.59h2.977v7.417h7.757v-7.418h2.978V56.35h-2.978v-7.836h-7.757v7.836h-2.977zM114.409 56.402c-3.213 0-5.146-1.933-5.146-5.119v-7.627c0-3.186 1.933-5.119 5.146-5.119h2.977c3.187 0 5.12 1.933 5.12 5.12v7.626c0 3.186-1.933 5.12-5.12 5.12h-2.977zm-2.168-5.145c0 1.698.914 2.638 2.56 2.638h2.167c1.646 0 2.56-.94 2.56-2.638v-7.575c0-1.723-.914-2.638-2.56-2.638h-2.167c-1.646 0-2.56.915-2.56 2.639v7.574zM134.899 56.402c-3.186 0-5.119-1.933-5.119-5.119V38.589h2.978v12.694c0 1.698.888 2.612 2.559 2.612h1.881c1.671 0 2.585-.914 2.585-2.612V38.589h2.978v12.694c0 3.186-1.933 5.12-5.145 5.12h-2.717zM155.184 56.402c-3.473 0-5.223-1.75-5.223-4.806h2.821c0 1.49.94 2.351 2.533 2.351h1.959c1.463 0 2.351-.836 2.351-2.22v-.914c0-1.437-.888-2.273-2.351-2.273h-2.586c-2.977 0-4.727-1.75-4.727-4.7v-.602c0-2.977 1.75-4.7 4.727-4.7h2.978c3.16 0 4.779 1.723 4.779 4.492h-2.82c0-1.306-.784-2.064-2.22-2.064h-2.325c-1.489 0-2.272.758-2.272 2.116v.731c0 1.384.783 2.168 2.272 2.168h2.481c3.187 0 4.937 1.802 4.937 4.832v.783c0 3.03-1.802 4.806-4.937 4.806h-2.377zM169.666 56.35V38.59h11.806v2.507h-8.828v4.962h8.828v2.455h-8.828v5.329h8.828v2.507h-11.806z"
        fill="#1A3C2E"
      />
      <Circle cx={90.7972} cy={9.03015} r={9.03015} fill="#FFD457" />
      <Path
        d="M85.827 11.986c-.1 0-.172.1-.14.195l.64 1.922c.02.06.077.101.14.101h.462c.09 0 .16.08.146.17l-1.637 10.793a.148.148 0 01-.146.125H84.25c-.064 0-.12.041-.14.101l-.64 1.922a.148.148 0 00.14.195h14.374a.148.148 0 00.14-.195l-.64-1.922a.148.148 0 00-.14-.1H96.3a.148.148 0 01-.146-.126l-1.636-10.793a.148.148 0 01.146-.17h.46c.064 0 .12-.04.14-.101l.641-1.922a.148.148 0 00-.14-.195h-9.939z"
        fill="#1A3C2E"
      />
      <Mask
        id={id1}
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={83}
        y={11}
        width={16}
        height={17}>
        <Path
          d="M85.827 11.986c-.1 0-.172.1-.14.195l.64 1.922c.02.06.077.101.14.101h.462c.09 0 .16.08.146.17l-1.637 10.793a.148.148 0 01-.146.125H84.25c-.064 0-.12.041-.14.101l-.64 1.922a.148.148 0 00.14.195h14.374a.148.148 0 00.14-.195l-.64-1.922a.148.148 0 00-.14-.1H96.3a.148.148 0 01-.146-.126l-1.636-10.793a.148.148 0 01.146-.17h.46c.064 0 .12-.04.14-.101l.641-1.922a.148.148 0 00-.14-.195h-9.939z"
          fill="#060606"
        />
      </Mask>
      <G mask={`url(#${id1})`}>
        <Path d="M84.001 22.379l12.344-4.572" stroke="#fff" strokeWidth={2.36553} />
      </G>
      <Rect x={90.0576} y={9.0293} width={1.47846} height={2.21769} rx={0.147846} fill="#1A3C2E" />
      <Rect x={92.2754} y={9.0293} width={1.47846} height={2.21769} rx={0.147846} fill="#1A3C2E" />
      <Rect x={87.8394} y={9.0293} width={1.47846} height={2.21769} rx={0.147846} fill="#1A3C2E" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.579 8.292H93.014c1.441 0 2.039-1.279 2.19-2.053a.14.14 0 00-.14-.165h-8.535a.14.14 0 00-.141.165c.152.774.75 2.053 2.19 2.053z"
        fill="#1A3C2E"
      />
      <Path
        d="M88.535 5.074c-.104.09-.041.26.096.26h4.36c.137 0 .2-.17.097-.26l-1.79-1.54a.77.77 0 00-.973 0l-1.79 1.54z"
        fill="#1A3C2E"
      />
    </Svg>
  );
}

export default BelhavenLogo;
