import React from "react";
import { View, StyleSheet } from "react-native";

import AnalyticsAccordion from "../AnalyticsAccordion/AnalyticsAccordion";
import AnalyticsItemList from "../AnalyticsItemList/TaskList/AnalyticsItemList";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

/** Next-level mapping: if we are at "course", children are "modules". Etc. */
const nextLevelMapping = {
  course: "module",
  module: "activity",
  activity: "objective",
};

function getCurrentLevel(breadcrumb) {
  console.log("breadcrumb", breadcrumb);
  if (!breadcrumb || !breadcrumb.length) return "course";
  return breadcrumb[breadcrumb.length - 1].level || "course";
}

/**
 * Example "struggle" metric: confusion + distractions
 */
function getStruggleScore(item) {
  return (item.confusion_points || 0) + (item.distractions || 0);
}

/**
 * Example "engagement" metric: success_points + questions_asked + active_interaction_count
 */
function getEngagementScore(item) {
  return (item.success_points || 0) + (item.questions_asked || 0) + (item.active_interaction_count || 0);
}

/**
 * Transform a raw item (course, module, or activity) into the shape
 * needed by <AnalyticsItemList>, including .level and .details for drilldown
 */
function transformForList(raw, index, currentLevel) {
  const nextLevel = nextLevelMapping[currentLevel] || "module";

  return {
    // for FlatList keys
    id: `${raw.label}-${index}`,
    name: raw.label || "Untitled",
    avgTime: "N/A", // or real time if you have it

    // raw fields for display
    successPoints: raw.success_points || 0,
    confusionPoints: raw.confusion_points || 0,
    questionsAsked: raw.questions_asked || 0,
    activeInteractionCount: raw.active_interaction_count || 0,
    enrollment: raw.enrollment || 0,
    sentiment: raw.sentiment || "neutral",
    distractions: raw.distractions || 0,

    // for logic
    struggleScore: getStruggleScore(raw),
    engagementScore: getEngagementScore(raw),

    // for handleDrillDown
    label: raw.label,
    details: raw.details, // includes sub-array: modules/activities/objectives
    level: currentLevel,
    nextLevel, // might be "activity", "objective", etc.
  };
}

/**
 * Return the array of "child" items for a raw item, given the current level
 * e.g., if level="course", children = item.details.modules
 */
function getChildrenArray(raw, currentLevel) {
  console.log("currentLevel", currentLevel);
  console.log("raw.details", raw.details);
  if (!raw.details) return [];

  if (currentLevel === "course" && Array.isArray(raw.details.modules)) {
    return raw.details.modules;
  } else if (currentLevel === "module" && Array.isArray(raw.details.activities)) {
    return raw.details.activities;
  } else if (currentLevel === "activity" && Array.isArray(raw.details.objectives)) {
    return raw.details.objectives;
  } else {
    return [];
  }
}

/**
 * Given an array of items with .struggleScore and .engagementScore,
 * pick the single "most struggling" and single "most engaging."
 */
function pickStrugglingAndEngaging(items) {
  if (!items.length) return { struggling: null, engaging: null };

  // If there's only one item, categorize it based on its struggle and engagement scores
  if (items.length === 1) {
    const singleItem = items[0];
    if (singleItem.engagementScore >= singleItem.struggleScore) {
      return { struggling: null, engaging: singleItem }; // Engage if engagement is higher or equal
    } else {
      return { struggling: singleItem, engaging: null }; // Struggling if struggle is higher
    }
  }

  // Sort descending by engagement score
  const sortedByEngagement = [...items].sort((a, b) => b.engagementScore - a.engagementScore);
  const engaging = sortedByEngagement[0]; // The most engaging item

  // Sort descending by struggle score
  const sortedByStruggle = [...items].sort((a, b) => b.struggleScore - a.struggleScore);
  const struggling = sortedByStruggle[0]; // The most struggling item

  // Ensure that the struggling item isn't the same as the engaging item, but prioritize engagement
  if (engaging === struggling) {
    // If both struggle and engagement scores are similar for the same item, prioritize engagement
    return { struggling: null, engaging };
  }

  return { struggling, engaging };
}

function AnalyticsAccordionList({ data, onListDrillDown, breadcrumb }) {
  // data is an array of items at the *current* level (courses, modules, or activities)
  const level = getCurrentLevel(breadcrumb);

  // Make sure data is an array
  const topLevelItems = Array.isArray(data) ? data : [];

  return (
    <View style={styles.container}>
      {topLevelItems.map((rawItem, idx) => {
        // "rawItem" is a course if level="course",
        // or a module if level="module", etc.

        // Let's gather that item’s children array
        const children = getChildrenArray(rawItem, level);

        // Transform them for <AnalyticsItemList />
        const listItems = children.map((child, i) => transformForList(child, i, level));

        if (listItems.length === 0) {
          // No children => skip or show empty
          return (
            <AnalyticsAccordion
              key={idx}
              title={rawItem.label}
              onExplorePress={() => {
                onListDrillDown?.(rawItem);
              }}>
              {/* Could show a "No data" message here */}
            </AnalyticsAccordion>
          );
        }

        // Pick the single "most struggling" & "most engaging"
        const { struggling, engaging } = pickStrugglingAndEngaging(listItems);
        const strugglingData = struggling ? [struggling] : [];
        const engagingData = engaging ? [engaging] : [];

        return (
          <AnalyticsAccordion
            key={idx}
            title={rawItem.label}
            onExplorePress={() => {
              onListDrillDown?.(rawItem);
            }}>
            <View style={styles.row}>
              <View style={styles.column}>
                <AnalyticsItemList
                  headerText="Most Struggling"
                  headerColor="#FFF1F3"
                  items={{ data: { data: strugglingData } }}
                  onListItemPress={(clickedItem) => {
                    onListDrillDown?.(clickedItem);
                  }}
                />
              </View>
              <CustomSpacing type="horizontal" size="l" />
              <View style={styles.column}>
                <AnalyticsItemList
                  headerText="Most Engaging"
                  headerColor="#F3FEE7"
                  items={{ data: { data: engagingData } }}
                  onListItemPress={(clickedItem) => {
                    onListDrillDown?.(clickedItem);
                  }}
                />
              </View>
            </View>
          </AnalyticsAccordion>
        );
      })}
    </View>
  );
}

export default AnalyticsAccordionList;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 16,
    paddingTop: 10,
  },
  row: {
    flexDirection: "row",
  },
  column: {
    flex: 1,
    paddingHorizontal: 5,
  },
});
