import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import ActivityCard from "../ActivityCard/ActivityCard";

const ActivityCardList = ({ module, onItemOptionsPress, onItemToggle, selectedItemId, style }) => {
  // Combine aitutors and pages from module resources
  const items = [
    ...(module?.resources?.[0]?.aitutors || []).map((item) => ({
      ...item,
      type: "Interactive Learning Experience",
    })),
    ...(module?.resources?.[0]?.pages || []),
  ];

  return (
    <View style={[styles.container, style]}>
      <ScrollView style={styles.scrollView}>
        {items.map((item, index) => (
          <ActivityCard
            key={item.id}
            id={item.id}
            title={item.title}
            type={item.type}
            onOptionsPress={(action) => {
              onItemOptionsPress(action, item);
            }}
            onToggle={() => onItemToggle?.(item, module)}
            style={[
              styles.card,
              index === items.length - 1 && styles.lastCard,
              selectedItemId === item.id && styles.selectedCard,
            ]}
          />
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 16,
  },
  scrollView: {
    flex: 1,
  },
  card: {
    width: "100%",
    marginBottom: 8,
  },
  lastCard: {
    marginBottom: 16,
  },
  selectedCard: {
    borderColor: "#3B82F6",
    borderWidth: 2,
  },
});

export default ActivityCardList;
