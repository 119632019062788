import React, { useState } from "react";
import AddModule from "../AddModule/AddModule";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../common/general/CustomModal/CustomModal";

const AddModuleModalContainer = ({ visible, onClose, onAddModule, hasOrder }) => {
  const { isMedium } = useResponsiveScreen();

  const [moduleName, setModuleName] = useState("");
  const [order, setOrder] = useState(0);

  return (
    <CustomModal visible={visible} onRequestClose={onClose} style={{ width: isMedium ? "50%" : "90%", padding: 0 }}>
      <AddModule
        addButtonClick={() => onAddModule(moduleName, order)}
        setOrder={setOrder}
        onChangeModuleName={setModuleName}
        hasOrder={hasOrder}
        cancelButtonClick={onClose}
      />
    </CustomModal>
  );
};

export default AddModuleModalContainer;
