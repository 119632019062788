import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M17.227 15.464a2.812 2.812 0 10-4.453 0 3.418 3.418 0 00-1.178 1.797.937.937 0 001.475.982.938.938 0 00.335-.502c.188-.694.84-1.178 1.594-1.178.754 0 1.406.484 1.594 1.178a.937.937 0 001.811-.482 3.414 3.414 0 00-1.178-1.795zM15 12.812a.937.937 0 110 1.875.937.937 0 010-1.874zm1.875-7.5h-6.454L8.39 3.025A1.563 1.563 0 007.22 2.5H3.125a1.562 1.562 0 00-1.563 1.563v11.61a1.516 1.516 0 001.515 1.514h5.717a.938.938 0 000-1.875H3.437V7.188h13.126v1.25a.938.938 0 001.875 0V6.875a1.563 1.563 0 00-1.563-1.563zm-13.438 0v-.937H7.08l.833.938H3.438z"
        fill="#98A2B3"
      />
    </Svg>
  );
}

export default SvgComponent;
