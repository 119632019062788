import React from "react";
import CustomModal from "../../../../components/common/general/CustomModal/CustomModal";
import ExportAccount from "../../../../components/settings/ExportAccount/ExportAccount";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";

const ExportAccountModalContainer = ({ visible, onClose, onExport }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title="Export Account Data"
      style={{ width: isMedium ? "50%" : "90%" }}
      exitFill={undefined}
      onShow={undefined}
      ariaLabel={undefined}>
      <ExportAccount cancelButtonClick={onClose} exportButtonClick={onExport} />
    </CustomModal>
  );
};

export default ExportAccountModalContainer;
