import React from "react";
import { Image, MotiView } from "moti";
import { View, Text, Pressable, StyleSheet } from "react-native";
import Divider from "../../common/layout/Divider/Divider";
import CustomProgressBar from "../../common/general/CustomProgressBar/CustomProgressBar";
import TimeLeftSmall from "../../svgs/common/TimeLeftSmall";
import CoreLabel from "../../svgs/common/CoreLabel";
import ElectiveLabel from "../../svgs/common/ElectiveLabel";
import GeneralLabel from "../../svgs/common/GeneralLabel";
import CheckedBadge from "../../svgs/common/CheckedBadge";
import { PlusCircle } from "../../svgIcons";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { shadowStyles } from "../../../styles/shadow";
import Edit from "../../svgs/common/Edit";
import { ChartLine } from "../../svgs/common";
import { useAppConfig } from "../../../AppConfigProvider";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import RoundedLabel from "../../common/label/RoundedLabel/RoundedLabel";

function ClassCardProgress({
  title,
  isHovered,
  onPress,
  onHoverIn,
  onHoverOut,
  type = "core",
  timeLeft,
  isDraft = false,
  progress = 100,
  hasEdit = false,
  hasAnalytics = false,
  onEditPress = false,
  onAnalyticsPress = false,
  thumbnail,
  isPlaceholder = false,
}) {
  const { primaryColor } = useAppConfig();

  const getBadgeConfig = (type) => {
    switch (type.toLowerCase()) {
      case "core":
        return {
          Icon: CoreLabel,
          text: "Core",
          style: { backgroundColor: "#054CD4" },
        };
      case "elective":
        return {
          Icon: ElectiveLabel,
          text: "Elective",
          style: { backgroundColor: "#7839EE" },
        };
      case "general":
        return {
          Icon: GeneralLabel,
          text: "General",
          style: { backgroundColor: "#008383" },
        };
      default:
        return {
          Icon: CoreLabel,
          text: "Core",
          style: { backgroundColor: "#054CD4" },
        };
    }
  };

  const getStatusText = (progress) => {
    if (typeof progress === "number") {
      if (progress === 0) {
        return "Not Started";
      } else if (progress > 0 && progress < 100) {
        return "In Progress";
      } else if (progress >= 100) {
        return "Complete";
      }
    }
    return "Not Started";
  };

  const badgeConfig = getBadgeConfig(type);
  const statusText = getStatusText(progress);
  const isComplete = progress >= 100;

  if (isPlaceholder) {
    return (
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
        }}
        delay={1000}
        transition={{ type: "timing", duration: 500 }}>
        <Pressable
          onPress={onPress}
          onHoverIn={onHoverIn}
          onHoverOut={onHoverOut}
          style={[
            styles.axioCard,
            styles.placeholderCard,
            isHovered && styles.axioCardHovered,
            { cursor: isHovered ? "pointer" : "default" },
          ]}>
          <View style={styles.placeholderContent}>
            <CustomButton
              text={" " + type.charAt(0).toUpperCase() + type.slice(1)}
              styleType="primaryLight"
              onPress={null}
              leftIcon={<PlusCircle />}
              aria-label="Add a Course"
              style={[styles.addButton, shadowStyles.allAroundShadow]}
            />
          </View>
        </Pressable>
      </MotiView>
    );
  }

  return (
    <MotiView
      from={{
        opacity: 0,
        translateY: 20,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
      delay={1000}
      transition={{ type: "timing", duration: 500 }}
      aria-label={`Class card ${title}`}
      style={styles.container}>
      <Pressable
        onPress={onPress}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
        style={[styles.axioCard, isHovered && styles.axioCardHovered]}>
        <View style={styles.axioContainer}>
          <View style={styles.headerRow}>
            <View style={styles.buttonRow}>
              <View style={[styles.badge, badgeConfig.style]}>
                <badgeConfig.Icon />
                <Text style={styles.badgeText}>{badgeConfig.text}</Text>
              </View>
              {isDraft && (
                <>
                  <CustomSpacing type="horizontal" size="s" />
                  <RoundedLabel
                    text="Draft"
                    textStyle={{ fontSize: 14 }}
                    style={styles.draftLabel}
                    padding={[8, 6]}
                    isRounded="true"
                  />
                </>
              )}
            </View>
            <View style={styles.buttonRow}>
              {hasEdit && (
                <CustomButton
                  leftIcon={<Edit fill={primaryColor} />}
                  style={shadowStyles.allAroundShadow}
                  onPress={onEditPress}
                />
              )}
              {hasAnalytics && (
                <>
                  <CustomSpacing type="horizontal" size="xs" />
                  <CustomButton
                    leftIcon={<ChartLine fill={primaryColor} />}
                    style={shadowStyles.allAroundShadow}
                    onPress={onAnalyticsPress}
                  />
                </>
              )}
            </View>
          </View>
          <View style={styles.titleContainer}>
            {thumbnail && <Image source={{ uri: thumbnail }} style={styles.thumbnail} />}
            <Text style={styles.axioTitle} numberOfLines={2} ellipsizeMode="tail">
              {title}
            </Text>
          </View>
          {isComplete && (
            <View style={styles.checkmarkContainer}>
              <CheckedBadge />
            </View>
          )}
          <Divider type="vertical" size="xs" />
          <View style={styles.progressContainer}>
            <View style={styles.progressStatus}>
              <Text style={styles.statusText}>{statusText}</Text>
              {!isComplete && timeLeft && (
                <View style={styles.timeContainer}>
                  <TimeLeftSmall size={16} color="#667085" />
                  <Text style={styles.timeLeftText}>{timeLeft}</Text>
                </View>
              )}
            </View>
            <CustomProgressBar ariaLabel="Course progress bar" progress={progress} color={"#3E68FE"} />
          </View>
        </View>
      </Pressable>
    </MotiView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 400,
  },
  axioCard: {
    backgroundColor: "#FFFFFF",
    borderRadius: 24,
    padding: 24,
    marginVertical: 8,
    marginHorizontal: 16,
    flexGrow: 1,
    borderWidth: 1,
    borderColor: "#EAECF0",
    outlineWidth: 0, // Prevent default outline flash
    outlineColor: "transparent", // Explicitly set to transparent
    outlineStyle: "solid", // Required for consistency
    transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1)", // Smooth transition
  },
  axioCardHovered: {
    outlineWidth: 3, // Simulate a thicker border
    outlineColor: "#D0D5DD", // Desired hover color
    outlineStyle: "solid",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional shadow for hover effect
    cursor: "pointer",
  },
  axioContainer: {
    gap: 12,
  },
  badge: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 100,
    paddingHorizontal: 12,
    paddingVertical: 4,
  },
  badgeText: {
    marginLeft: 4,
    lineHeight: 18,
    fontWeight: 600,
    fontFamily: "Inter",
    color: "#FFFFFF",
    fontSize: 12,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    justifyContent: "center",
  },
  thumbnail: {
    width: "100%",
    height: 140,
    borderRadius: 12,
    objectFit: "cover",
  },
  axioTitle: {
    fontSize: 16,
    fontWeight: "600",
    color: "#101828",
    lineHeight: 24,
  },
  progressContainer: {
    gap: 8,
  },
  progressStatus: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusText: {
    fontSize: 14,
    fontWeight: "500",
    color: "#667085",
  },
  checkmarkContainer: {
    position: "absolute",
    top: 3,
    right: 3,
  },
  timeContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
  },
  timeLeftText: {
    fontSize: 14,
    color: "#667085",
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  placeholderCard: {
    backgroundColor: "#FFFFFF",
    borderWidth: 2,
    borderColor: "#D0D5DD",
    borderStyle: "dashed",
    borderRadius: 24,
    padding: 24,
    marginVertical: 8,
    marginHorizontal: 16,
    maxWidth: 400,
    minHeight: 242,
    minWidth: 400,
    justifyContent: "center",
    alignItems: "center",
  },
  placeholderContent: {
    alignItems: "center",
    justifyContent: "center",
    gap: 12,
  },
  headerRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  draftLabel: {
    backgroundColor: "#EAECF0",
    borderRadius: 360,
  },
});

export default ClassCardProgress;
